<template>
  <div class="login__container">
    <div class="login__logo">
      <img src="../assets/LogoMg.svg" alt="">
    </div>
    <v-card elevation="0" class="login__card_container card">
      
      <v-form
        ref="form"
        class="pa-10"
        v-model="valid"
        lazy-validation
      >
        <div class="d-flex flex-column align-center justify-center">
          <p class="" style="font-weight: 500; font-size: 25px; line-height: 30px;">Tizimga kirish</p>
          <p class="">Kirish uchun loginingiz va parolingizni kiriting</p>
        </div>

        <v-text-field class=""
          v-model="name"
          prepend-inner-icon="mdi-account-circle-outline"
          style="height: 85px;"
          outlined
          :rules="[v => !!v || 'Iltimos, loginningizni kiriting!']"
          label="Login"
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          :counter="8"
          label="Parol"
          :rules="[v => !!v || 'Iltimos, parolingizni kiriting!']"
          outlined
          prepend-inner-icon="mdi-key-plus"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"
          required
        ></v-text-field>

        <v-select
          v-model="role"
          style="height: 85px;"
          prepend-inner-icon="mdi-account-multiple"
          outlined
          :items="state"
          :rules="[v => !!v || 'Iltimos, rolingizni tanlang!']"
          label="Roleni tanlang"
          required
        ></v-select>

        <div>
          <p class="font-weight-bold">Yilni tanlang: </p>
          <VueDatePicker
            v-model="date"
            min-date="2015"
            placeholder="Yilni tanlang"
            type="year"
          />
        </div>

        <div v-if="checkDate">
          <p class="font-weight-bold">Kvartalni tanlang: </p>
          <v-combobox clearable dense
                      v-model="select" :items="items"></v-combobox>
        </div>

        <v-btn :disabled="!loginDisable || disabled" color="primary" class="mr-4 mt-1"
                style="background: #507BE8;border-radius: 12px;"
                elevation="0" large block @click.prevent="login">
          Kirish
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
// import axios from "axios";
// axios.defaults.withCredentials = true;
export default {
  name: "Authentication",
  data() {
    return {
      name: '',
      password: '',
      checkbox: '',
      role: '',
      disabled: false,
      valid: false,
      show1: false,
      state: ['Admin', 'Seller'],
      date: null,
      items: [
        '1-kvartal',
        '2-kvartal',
        '3-kvartal',
        '4-kvartal',
      ],
      select: ''
    }
  },

  computed: {
    loginDisable() {
      if (this.date === '2021') {
        return this.name && this.password && this.state && this.role && this.date;
      }
      return this.name && this.password && this.state && this.role && this.date && this.select;
    },
    checkDate() {
      return this.date !== '2021'; // check date 2021 true and false
    },
    // selectRole() {
    //   if (this.role === 'Admin') {
    //     return this.role = 'Admin';
    //   } else {
    //     return this.role = 'Seller';
    //   }
    // }
  },
  methods: {
    login() {
      let year = this.date;

      // let config = {
      //   headers: {'Content-Type': 'application/json'},
      //   responseType: 'json'
      // };

      if (this.date === '2021') {
        const cvartal = [year + "-01-01", year + "-12-31"];
        localStorage.setItem('cvartal', cvartal)
      }

      if (this.select === '1-kvartal') {
        const firstCvartal = [year + "-01-01", year + "-03-31"];
        localStorage.setItem('cvartal', firstCvartal)
      } else if (this.select === '2-kvartal') {
        const secondCvartal = [year + "-04-01", year + "-06-30"];
        localStorage.setItem('cvartal', secondCvartal)
      } else if (this.select === '3-kvartal') {
        const thirdCvartal = [year + "-07-01", year + "-09-30"];
        localStorage.setItem('cvartal', thirdCvartal)
      } else if (this.select === '4-kvartal') {
        const fourthCvartal = [year + "-10-01", year + "-12-31"];
        localStorage.setItem('cvartal', fourthCvartal)
      }


      // axios.get('/sanctum/csrf-cookie', config).then(response => {
        this.$axios
          .post("/login", {
              email: this.name,
              password: this.password,
              role: this.role
            }, {
              withCredentials: true,
              headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', credentials: 'include',}
            }
          )
          .then(response => {
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('role', response.data.role)
            this.$store.dispatch('setMessage', 'Hush kelibsiz!')
            if (response.data.role === 'admin') {
              this.$router.replace('/dashboard')
            } else if (response.data.role === 'seller') {
              this.$router.replace('/seller/enter')
            }
            this.name = ''
            this.password = ''
            this.role = ''
            return response
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.dispatch('setErrorMessage', 'Login yoki parolingiz xato, iltimos qayta kiriting!')
              this.name = ''
              this.password = ''
              this.role = ''
              this.loginDisable()
            }
          });
        // return response;
      // });
    },
  },
}
</script>

<style scoped>
.login__container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.login__logo{
  position: absolute;
  left: 120px;
  top: 70px;
}
.login__card_container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  background: rgba(255, 255, 255, 0.3) !important;
  box-shadow: 0px 30px 50px rgba(24, 26, 29, 0.03) !important;
  backdrop-filter: blur(42px) !important;
  justify-content: center;
  align-content: center;
  border-radius: 24px;
}


/* .login {
  align-content: center;
  justify-content: center;
  justify-items: center;
  margin-top: 50px;
} */

/* .imageLogo {
  position: absolute;
  margin-top: 100px;
  margin-left: 100px;
}

.cirlce {
  background: #FFFFFF !important;
  width: 580px;
  height: 500px;
  margin-left: 120px;
  position: relative;
  background: url("../assets/mgLogoLogin.svg");
}



@media (min-width: 1600px) {
  .login {
    margin-left: 180px;
    margin-top: 100px;
  }

  .cirlce {
    width: 650px;
    height: 580px;
  }

  .card {
    width: 480px;
    margin-left: 85px;
    margin-top: 60px !important;
  }
} */










/* ----- MEDIA_QUERIES_SECTION START ----- */

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 2001px) {
  
    
}
  
/* 
##Device = Laptops, Desktops
##Screen = B/w 1681px to 2000px
*/
@media (min-width: 1681px) and (max-width: 2000px) {
    
    
}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1281px to 1680px
*/
@media (min-width: 1281px) and (max-width: 1680px) {
    
}
/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
    
}
  
/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .login__logo{
    position: absolute;
    top: 50px !important;
    left: 65px !important;
  }
  .login__logo img{
    width: 45px;
    height: 45px;
  }
}
  
/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .login__logo{
    position: absolute;
    top: 50px !important;
    left: 65px !important;
  }
  .login__logo img{
    width: 45px;
    height: 45px;
  }
}
  
/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  .login__logo{
    position: absolute;
    top: 50px !important;
    left: 55px !important;
  }
  .login__logo img{
    width: 40px;
    height: 40px;
  }
}
  
/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .login__logo{
    position: absolute;
    top: 40px !important;
    left: 45px !important;
  }
  .login__logo img{
    width: 30px;
    height: 30px;
  }
}
/* ----- MEDIA_QUERIES_SECTION END ----- */
</style>
