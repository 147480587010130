<template>
  <div>
    <v-app-bar app elevation="0" height="100" class="appBar" color="white">
      <div class="d-flex justify-space-between align-center flex-wrap px-5 w-100">

        <div class="d-flex align-center">
          <v-app-bar-nav-icon @click="drawerNav = !drawerNav" 
            class="hidden-md-and-up mr-3"></v-app-bar-nav-icon>
          <img class="mr-3" src="@/assets/convert-3d-cube.svg" alt="">
          <div>
            <h2>Omborxona</h2>
            <span>Bazada saqlanayotgan mahulotlar hisoboti</span>
          </div>
        </div>

        <div class="d-flex align-center">
          <v-text-field hide-details prepend-inner-icon="mdi-magnify" outlined dense
            label="Qidiruv..." v-model="search"></v-text-field>
        </div>
      </div>
    </v-app-bar>





    <div class="content__main">
      <v-row>
        <v-col v-for="(idx , i) of storageData" :key="i">
          <v-card max-width="350" height="140" outlined
            style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12); border-radius: 12px; border: none">
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex mb-4">
                  <img src="@/assets/basket.svg" alt="">
                  <p class="font-weight-medium textCardProduct mt-4 ml-2 text--secondary">
                    {{ idx.text }}
                  </p>
                </div>
                <div class="d-flex ml-2 " style="margin-top: -5px">
                  <p class="numberCardProduct">
                    {{ idx.scale }}
                  </p>
                  <p class="font-weight-medium mt-3 ml-2 text--secondary">
                    {{ idx.amount }}
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>



      <v-row class="mb-2">
        <v-col>
          <v-card max-width="350" height="140" outlined
                  style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12); border-radius: 12px; border: none">
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex mb-4">
                  <img src="@/assets/basket.svg" alt="">
                  <p class="font-weight-medium textCardProduct mt-4 ml-2 text--secondary">
                    SOTILGAN RASIPNOY SEMENT
                  </p>
                </div>
                <div class="d-flex ml-2 " style="margin-top: -5px">
                  <p class="numberCardProduct">
                    {{ simpleCementAmount + convertCementAmount + saleCement }}
                  </p>
                  <p class="font-weight-medium mt-3 ml-2 text--secondary">
                    tonna
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col>
          <v-card max-width="350" height="140" outlined
            style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12); border-radius: 12px; border: none">
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex mb-4">
                  <img src="@/assets/basket.svg" alt="">
                  <p class="font-weight-medium textCardProduct mt-4 ml-2 text--secondary">
                    SOTIB OLINGAN RASIPNOY SEMENT
                  </p>
                </div>
                <div class="d-flex ml-2 " style="margin-top: -5px">
                  <p class="numberCardProduct">
                    {{ incomeCement }}
                  </p>
                  <p class="font-weight-medium mt-3 ml-2 text--secondary">
                    tonna
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col>
          <v-card max-width="350" height="140" outlined
                  style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12); border-radius: 12px; border: none">
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex mb-4">
                  <img src="@/assets/basket.svg" alt="">
                  <p class="font-weight-medium textCardProduct mt-4 ml-2 text--secondary">
                    SOTIB OLINGAN ODDIY QOP
                  </p>
                </div>
                <div class="d-flex ml-2 " style="margin-top: -5px">
                  <p class="numberCardProduct">
                    {{ incomeSimpleTin }}
                  </p>
                  <p class="font-weight-medium mt-3 ml-2 text--secondary">
                    tonna
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col>
          <v-card max-width="350" height="140" outlined
                  style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12); border-radius: 12px; border: none">
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex mb-4">
                  <img src="@/assets/basket.svg" alt="">
                  <p class="font-weight-medium textCardProduct mt-4 ml-2 text--secondary">
                    SOTIB OLINGAN KONVERT QOP
                  </p>
                </div>
                <div class="d-flex ml-2 " style="margin-top: -5px">
                  <p class="numberCardProduct">
                    {{ incomeConvertTin }}
                  </p>
                  <p class="font-weight-medium mt-3 ml-2 text--secondary">
                    tonna
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col>
          <v-card max-width="350" height="140" outlined
                  style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12); border-radius: 12px; border: none">
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex mb-4">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="12" fill="#F2F5FA"/>
                    <path
                      d="M16.6719 22.3298C16.6719 23.6198 17.6619 24.6598 18.8919 24.6598H21.4019C22.4719 24.6598 23.3419 23.7498 23.3419 22.6298C23.3419 21.4098 22.8119 20.9798 22.0219 20.6998L17.9919 19.2998C17.2019 19.0198 16.6719 18.5898 16.6719 17.3698C16.6719 16.2498 17.5419 15.3398 18.6119 15.3398H21.1219C22.3519 15.3398 23.3419 16.3798 23.3419 17.6698"
                      stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20 14V26" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path
                      d="M20 30C25.5228 30 30 25.5228 30 20C30 14.4772 25.5228 10 20 10C14.4772 10 10 14.4772 10 20C10 25.5228 14.4772 30 20 30Z"
                      stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>


                  <p class="font-weight-medium textCardProduct mt-4 ml-2 text--secondary">
                    SEMENTNING O'RTACHA NARXI
                  </p>
                </div>
                <div class="d-flex ml-2 " style="margin-top: -5px">
                  <p class="numberCardProduct">
                    {{ middlePrice }}
                  </p>
                  <p class="font-weight-medium mt-3 ml-2 text--secondary">
                    so'm
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <div>
        <v-divider></v-divider>
        <v-row class="align-center">
          <v-spacer></v-spacer>
          <div class="d-flex my-5">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                    :return-value.sync="date" transition="scale-transition"
                    offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date" class="mr-8" hide-details
                  outlined label="Sanani tanlang" prepend-icon="mdi-calendar"
                  readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model.lazy="date" :min="getMinDate" :max="getMaxDate"
                              no-title range>
                <v-spacer></v-spacer>
                <v-btn text color="primary"
                        @click="date = []">
                  O'chirish
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </v-row>
        <v-divider></v-divider>
      </div>

        <v-card elevation="0" style="background: #FCFDFF!important;">
          <div class="d-flex flex-wrap-xs-sm w-100">
            <div class="w-100" style="overflow-y: scroll; height: 600px;">
              <div v-if="!(Object.keys(incomesStorage).length === 0)">
                <v-list class="bgNone" flat>
                  <v-list-item-group class="px-2">
                    <v-list-item
                      v-for="(income) in incomesStorage"
                      :key="income.id"
                      class="py-2 px-0 d-flex w-100">
                      
                          <img src="@/assets/enter.svg" alt="" class="px-2">

                          <v-list-item-title class="text--secondary text-center" v-if="income.product_id === 1">
                            <span class="font-weight-medium">{{ income.amount }}</span> t
                          </v-list-item-title>
                          <v-list-item-title class="text--secondary text-center" v-if="income.product_id === 2">
                            <span class="font-weight-medium">{{ income.amount }}</span> ta konvert qop
                          </v-list-item-title>
                          <v-list-item-title class="text--secondary text-center" v-if="income.product_id === 3">
                            <span class="font-weight-medium">{{ income.amount }}</span> ta oddiy qop
                          </v-list-item-title>


                          <v-list-item-title class="text--secondary text-center">
                            {{ income.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                          </v-list-item-title>

                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
              <Nodata v-else></Nodata>
            </div>

            <div class="w-100" style="overflow-y: scroll; height: 600px;">
              <div v-if="!(Object.keys(salesStorage).length === 0)">
                <v-list class="bgNone" flat>
                  <v-list-item-group class="px-2">
                    <v-list-item v-for="(sale) in salesStorage"
                      :key="sale.id"
                      class="py-2 px-0 d-flex w-100">
                          <img src="@/assets/chiqim.svg" alt="" class="px-2">

                          <v-list-item-title class="text--secondary text-center" v-if="sale.product_id === 1">
                            <span class="font-weight-medium">{{ sale.amount }}</span> t
                          </v-list-item-title>
                          <v-list-item-title class="text--secondary text-center" v-if="sale.product_id === 2">
                            <span class="font-weight-medium">{{ sale.amount }} t {{ sale.quantity }}</span>
                            ta konvert qop
                          </v-list-item-title>
                          <v-list-item-title class="text--secondary text-center" v-if="sale.product_id === 3">
                            <span class="font-weight-medium">{{ sale.amount }} t {{ sale.quantity }}</span>
                            ta oddiy qop
                          </v-list-item-title>

                          <v-list-item-title class="text--secondary text-center">
                            {{ sale.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                          </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
              <Nodata v-else></Nodata>
            </div>

          </div>
        </v-card>
    </div>
  </div>
</template>

<script>
import Nodata from '../components/base/NoDate'

export default {
  components: {Nodata},
  name: "Storage",
  data() {
    return {
      loadingContent: false,
      incomes: {},
      fieldsIncomesEdit: [],
      sales: [],
      presets: '',
      maxDate: '',
      minDate: '',
      dialogSecond: false,
      dialog: false,
      dialog2: false,
      menu: '',
      arrOfAdd: [],
      search: '',
      totalCement: 0,
      modalArray: [],
      date: [],
      storageData: [
        {text: 'RASSIPNOY SEMENT', scale: 'Loading...', bg: false, amount: 'tonna'},
        {text: 'SOTILGAN ODDIY QOP', scale: 'Loading...', bg: true, value: 'simple', amount: 'ta'},
        {text: 'SOTILGAN KONVERT QOP', scale: 'Loading...', bg: true, value: 'convert', amount: 'ta'},
        {text: 'ODDIY QOP', scale: 'Loading...', bg: false, amount: 'ta'},
        {text: 'KONVERT QOP', scale: 'Loading...', bg: false, amount: 'ta'},
      ],
      selection: [
        {text: 'QOP', value: "tin"},
        {text: 'SEMENT', value: "cement"},
      ],
      items1: [
        {text: 'RASSIPNOY SEMENT', scale: this.totalCement, bg: true},
        {text: 'ODDIY QOPLI SEMENT', scale: '550', bg: false},
        {text: 'KONVERT QOPLI SEMENT', scale: '790', bg: true},
        {text: 'KONVERT QOP', scale: '5,455', bg: false},
        {text: 'KONVERT QOP', scale: '21,055', bg: true},

      ],
      storageSet: {
        quantity: '',
        amount: '',
        product_type: '',
        status_tin: '',
        dialogConvert: false
      },
      convertTinModal: false,
      chooseSelection: '',
      incomesStorage: [],
      salesStorage: [],
      simpleCementAmount: '',
      convertCementAmount: '',
      saleCement: '',
      incomeCement: '',
      incomeSimpleTin: '',
      incomeConvertTin: '',
      middlePrice: ''
    }
  },
  watch: {
    onDate() {
      this.getIncomes();
      this.getSales();
    }
  },

  mounted() {
    let cvartal = localStorage.getItem("cvartal");

    this.$axios.get("/get-data-storage?cvartal=" + cvartal).then(response => {
      this.storageData[1].scale = response.data[0] ?? 0;
      this.storageData[0].scale = response.data[2] ?? 0;
      this.storageData[3].scale = response.data[3] ?? 0;
      this.storageData[4].scale = response.data[4] ?? 0;
      this.storageData[2].scale = response.data[1] ?? 0;
      this.convertCementAmount = response.data[5] ?? 0;
      this.simpleCementAmount = response.data[6] ?? 0;
      this.saleCement = response.data[7] ?? 0;
      this.incomeCement = response.data[8] ?? 0;
      this.incomeSimpleTin = response.data[9] ?? 0;
      this.incomeConvertTin = response.data[10] ?? 0;
    });


    this.$axios.get("/middle-price?cvartal=" + cvartal).then(response => {
      this.middlePrice = response.data ?? 0;
    });

    this.getIncomes();
    this.getSales();
    this.getStorageAnalitic();
  },

  computed: {
    getMinDate() {
      let cvartal = localStorage.getItem("cvartal");
      return cvartal.substring(0, 10);
    },

    getMaxDate() {
      let cvartal = localStorage.getItem("cvartal");
      return cvartal.substring(11);
    },

    'onDate'() {
      return this.date;
    },
    
    drawerNav: {
        get: function() {
            return this.$store.state.common.drawerNav
        },
        set: function(newValue) {
            return this.$store.state.common.drawerNav = newValue
        }
    },

  },

  methods: {
    getIncomes() {
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      this.loadingContent = true;
      if (this.date.length) {
        dateUrl = '?date=' + this.date;
      } else {
        dateUrl = '';
      }
      this.$axios.get('/income-storage?cvartal=' + cvartal + dateUrl).then(response => {
        this.incomesStorage = response.data;
        this.loadingContent = false;
      }).catch(error => {
        console.log(error)
      });
    },


    getSales() {
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.date.length) {
        dateUrl = '?date=' + this.date;
      } else {
        dateUrl = '';
      }
      this.$axios.get('/sale-storage?cvartal=' + cvartal + dateUrl).then(response => {
        this.salesStorage = response.data;
      }).catch(error => {
        console.log(error)
      });
    }
    ,

    openWindowToAdd(item) {
      if (item.value === "simple") {
        this.dialogSecond = true
        this.storageSet.product_type = 'simple_tin_cement'
        this.storageSet.status_tin = 'simple_tin'
      } else {
        this.convertTinModal = true;
        this.storageSet.product_type = 'convert_tin_cement'
        this.storageSet.status_tin = 'convert_tin'
      }
    }
    ,

    addStorage() {

      let cvartal = localStorage.getItem("cvartal");
      this.$axios
        .post("/set-data-storage?cvartal=" + cvartal, this.storageSet)
        .then(response => {
          if (response.data.success === true) {
            this.getStorageAnalitic();
            this.convertTinModal = false;
            this.dialogSecond = false;
            this.storageSet = {};
            //Success notification message
            this.$toast.success(response.data.message);
          } else {
            this.$toast.error(response.data.message);
            this.storageSet = {};
            this.convertTinModal = false;
            this.dialogSecond = false;
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            //Error notification message
            this.$toast.error("Пожалуйста, попробуйте еще раз!");
          }
        });
    }
    ,

    getCementSales() {
      let searchUrl;
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.search) {
        searchUrl = '&search=' + this.search;
      } else {
        searchUrl = '';
      }
      if (this.date.length) {
        dateUrl = '&date=' + this.date;
      } else {
        dateUrl = '';
      }

      this.$axios.get('/sales-by-status?status=' + this.productType + '&cvartal=' + cvartal + searchUrl + dateUrl + '&page=' + this.cementSalesPagination.current).then(response => {
        this.cementSales = response.data.cementSales.data;
        this.cementSalesPagination.current = response.data.cementSales.current_page;
        this.cementSalesPagination.total = response.data.cementSales.last_page;
      }).catch(error => {
        console.log(error)
      });
    }
    ,

    getStorageAnalitic() {
      let cvartal = localStorage.getItem("cvartal");

      this.$axios.get("/get-data-storage?cvartal=" + cvartal).then(response => {
        this.storageData[1].scale = response.data[0] ?? 0;
        this.storageData[0].scale = response.data[2] ?? 0;
        this.storageData[3].scale = response.data[3] ?? 0;
        this.storageData[4].scale = response.data[4] ?? 0;
        this.storageData[2].scale = response.data[1] ?? 0;
      });
    }
  },
}
</script>

<style scoped>
  
</style>

