import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router/router'
import store from './store/index'
import '../public/fontGilroy/stylesheet.css'
import axios from "@/plugins/axios";
import VueMoment from 'vue-moment'
import Dropdown from 'vue-simple-search-dropdown';
import moment from 'moment-timezone'
import VueHead from 'vue-head'
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import './scss/main.css'

Vue.component('Dropdown', Dropdown);
moment.locale("uz-latn");

import money from 'v-money'
Vue.use(money, {precision: 4})

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

Vue.use(VueHead)
//Notification config
Vue.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
    position: "bottom-left",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
});

Vue.use(VueMoment, { moment })

Vue.use(VueDatePicker);


Vue.config.productionTip = false
Vue.prototype.$axios = axios;

new Vue({
    vuetify, router, store,
    render: h => h(App),
}).$mount('#app')
