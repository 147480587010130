import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)
export default {
    state: {
        authFields: {name: '', password: '', email: '',id:''},
    },
    mutations: {
        EDIT_USER(state, payload) {
          state.authFields.name=payload.name
          state.authFields.password=payload.password
          state.authFields.email=payload.email
          state.authFields.id=payload.id
        },
    },
    actions: {
        updateUser({commit}, payload) {
            commit('setLoading',true)
            axios.put(process.env.VUE_APP_API_BASE_URL  + '/auth/' + payload.id, payload)
                .then(response => {
                    commit('EDIT_USER', payload)
                    commit('clearMessage')
                    commit('setLoading',false)
                    commit('setMessage', 'Malumot ozgartirildi')
                    return response;
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        console.log(error)
                        commit('setMessage',error)
                        commit('setLoading',false)
                    }
                });
        },
    },
    getters: {

    }
}
