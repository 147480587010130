<template>
  <div>
    <v-card class="chartCard ml-3">
      <div id="chart">
        <div class="toolbar d-flex justify-space-between pt-4 pr-4">
          <h3 class="pl-5">
            Sotuvlar
          </h3>
        </div>
      </div>

      <apexchart ref="chart" type="area" height="350" :options="apexAreaChartProps.chartOptions"
        :series="apexAreaChartProps.series"></apexchart>
        
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from "../plugins/axios";

export default {
  components: {
    apexchart: VueApexCharts,
  },

  computed: {
    apexAreaChartProps() {
      return {
        series: [
          {
            name: 'Sotildi',
            data: this.analyticsData,
          },
        ],
        chartOptions: {
          legend: {
            show: true,
            showForSingleSeries: true,
          },
          colors: ['#507BE8', '#EDF1FD'],
          chart: {
            height: 500,
            type: 'area',
            toolbar: {
              show: false
            },
            background: '#fff',
            animations: {
              enabled: true,
              easing: 'linear',
              speed: 1500,
              animateGradually: {
                enabled: true,
                delay: 150
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350
              }
            },
            tooltip: {
              style: {
                fontSize: '12px',
                color: '',
              },
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            type: 'datemonth',
            categories: ["Yanvar", "Fevral", "Mart", "Aprel", "May", "Iyun", "Iyul", "Avgust", "Sentabr", "Oktabr", "Noyabr", "Dekabr"]
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value + " tonna";
              }
            },
          },
        },
      }
    }
  },

  data() {
    return {
      menu: false,
      date: '',
      analyticsData: []
    }
  },

   mounted() {
     axios.get("/get-chart-analytics").then(response => {
      this.analyticsData = response.data;
    });
  },
}
</script>

<style>
.apexcharts-tooltip {
  background: #010101 !important;
  color: #f1f1f1;
}

.chartCard {
  position: relative;
  background: white;
  box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12) !important;
  border-radius: 12px !important;
  border: none;
  /*width: 480px !important;*/
}

.chart {
  width: 430px;
  position: center;
}

@media (min-width: 1500px) {
  .chart {
    width: 480px;
  }

  .chartCard {
    /*width: 560px !important;*/
  }
}

@media (min-width: 1560px) {
  .chart {
    width: 500px;
  }

  .chartCard {
    /*width: 580px !important;*/
  }
}

@media (min-width: 1800px) {
  .chart {
    width: 650px !important;
  }

  .chartCard {
    /*width: 650px !important;*/
  }
}

@media (min-width: 1700px) {
  .chart {
    width: 530px;
  }
}

@media (min-width: 1900px) {
  .chart {
    width: 680px;
  }
}
</style>
