import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)
export default {
    state: {
        customers: [],
        fields: {name: '', phone_number: '', card_number: '',},
        customerAdd:{
            name:'',

        }
    },
    mutations: {
        SET_CUSTOMER(state, customers) {
            state.customers = customers
        },
        EDIT_CUSTOMER(state, payload) {
            const FindElement = state.customers.findIndex(el => el.id == payload.id)
            if (FindElement != -1) {
                state.customers[FindElement].name = payload.name
                state.customers[FindElement].phone_number = payload.phone_number
                state.customers[FindElement].card_number = payload.card_number
            }
        },
        GET_CUSTOMER_ID(state, payload) {
            state.fields = payload
        }
    },
    actions: {
        updateCustomer({commit, dispatch}, payload) {
            commit('setLoading',true)
            axios.put(process.env.VUE_APP_API_BASE_URL+ '/customers/' + payload.id, payload)
                .then(response => {
                    commit('EDIT_CUSTOMER', response.data)
                    dispatch('loadCustomersForCommit')
                    commit('clearMessage')
                    commit('setLoading',false)
                    commit('setMessage', 'new data')
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        console.log(error)
                        commit('setMessage')
                    }
                });
        },
        getCustomersId({commit}, payload) {
            axios.get(process.env.VUE_APP_API_BASE_URL+"/customers/" + payload.id).then(response => {
                commit('GET_CUSTOMER_ID', response.data)
            })
        },
        loadCustomers({commit}) {
            commit('setLoading', true)
            axios.get(process.env.VUE_APP_API_BASE_URL+'/customers')
                .then(result => result.data)
                .then(customers => {
                    commit('SET_CUSTOMER', customers)
                    commit('setLoading', false)
                })
            commit('clearMessage')
        },
        addCustomer({commit}){
          commit('')
        },
        loadCustomersForCommit({commit}) {
            commit('setLoading', true)
            axios.get(process.env.VUE_APP_API_BASE_URL+ '/customers')
                .then(result => result.data)
                .then(customers => {
                    commit('SET_CUSTOMER', customers)
                    commit('setLoading', false)
                })
            commit('clearMessage')
        },
    },
    getters: {
        customers(state) {
            return state.customers
        },
        fields(state) {
            return state.fields
        }
    }
}
