import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)
export default {
    state: {
        storage: [],
        fieldsStorage: {
            amount: '',
            quantity: '',
            simple:'',
            product_id:''
        },
        thinsCement:[],
        load: false
    },
    mutations: {
        SET_STORAGE(state, storage) {
            state.storage = storage
        },
        SET_ADD_STORAGE(state, payload) {
            state.fieldsStorage.amount = payload.amount
            state.fieldsStorage.quantity = payload.quantity
            state.fieldsStorage.simple = payload.simple
            state.fieldsStorage.product_id = payload.product_id
        },
        SET_THINS(state,payload){
          state.thinsCement=payload
        },
        load(state, payload) {
            state.load = payload
        },
    },
    actions: {
        addStorage({commit}, payload) {
            commit('setLoading', true)
            axios
                .post(process.env.VUE_APP_API_BASE_URL + "/set-data-storage", payload)
                .then(response => {
                    commit('SET_ADD_STORAGE', payload)
                    commit('setLoading', false)
                    commit('setMessage','muffaqiyatli qoshildi')
                    return response
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        console.log(error)
                    }
                });
        },
        loadThinsCement({commit}) {
            commit('setLoading', true)
            axios.get(process.env.VUE_APP_API_BASE_URL + "/get-data-storage")
                .then(async response => {
                   await commit('SET_THINS',response.data)
                    commit('setLoading', false)
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        console.log(error)
                    }
                });
        },

        loadStorage({commit}) {
            commit('setLoading', true)
            axios.get(process.env.VUE_APP_API_BASE_URL + '/storage')
                .then(result => result.data)
                .then(storage => {
                    commit('SET_STORAGE', storage)
                    commit('setLoading', false)
                })
            commit('clearMessage')
        },

    },
    getters: {
        storage(state) {
            return state.storage
        },
        fieldsStorage(state) {
            return state.fieldsStorage
        },
        load(state) {
            return state.load
        },
        thinsCement(state){
            return state.thinsCement
        }
    }
}
