import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)
export default {
    state: {
        sales: [],
        fieldsSales: {amount: '', total_price: '', price: '',paid:'',created_at:'',name:''},
        premium_customer:[],
        fieldsAdd: {
            customer_id: '',
            product_id: '',
            with_tin: '',
            tin_id: '',
            amount: '',
            price: '',
            quantity:'',
            total_price: '',
            paid: '',
            created_at: '',
            oldAmount:''
        },
        clientAdd: {
            name: '',
            phone_number: '',
            card_number: '',
        },
        oldAmount:'',
        oldQuantity:'',
    },
    mutations: {
        SET_SALES(state, sales) {
            state.sales = sales
        },
        SET_PREMIUM_CUSTOMER(state,premium){
          state.premium_customer=premium
        },
        ADD_SALES(state, payload) {
            state.fieldsAdd.customer_id = payload.customer_id
            state.fieldsAdd.quantity=payload.quantity
            state.fieldsAdd.product_id = payload.product_id
            state.fieldsAdd.with_tin = payload.with_tin
            state.fieldsAdd.tin_id = payload.tin_id
            state.fieldsAdd.amount = payload.amount
            state.fieldsAdd.price = payload.price
            state.fieldsAdd.total_price = payload.total_price
            state.fieldsAdd.paid = payload.paid
            state.fieldsAdd.created_at = payload.created_at
        },
        ADD_CLIENT(state, payload) {
            state.clientAdd.name = payload.name
            state.clientAdd.phone_number = payload.phone_number
            state.clientAdd.card_number = payload.card_number
        },
        CLEAN_SALES(state) {
            state.fieldsAdd.customer_id = ''
            state.fieldsAdd.product_id = ''
            state.fieldsAdd.with_tin = ''
            state.fieldsAdd.tin_id = ''
            state.fieldsAdd.amount = ''
            state.fieldsAdd.price = ''
            state.fieldsAdd.total_price = ''
            state.fieldsAdd.paid = ''
            state.fieldsAdd.created_at = ''
        },
        EDIT_SALES(state, payload) {
            const FindElement = state.sales.findIndex(el => el.id == payload.id)
            if (FindElement != -1) {
                state.sales[FindElement].amount = payload.amount
                state.sales[FindElement].quantity = payload.quantity
                state.sales[FindElement].total_price = payload.total_price
                state.sales[FindElement].price = payload.price
                state.sales[FindElement].paid = payload.paid
            }

        },
        GET_SALES_ID(state, payload) {
            state.fieldsSales = payload
            state.oldAmount= state.fieldsSales.amount
        }
    },
    actions: {
     async  updateSales({commit,dispatch}, payload) {
            commit('setLoading', true)
          await  axios.put(process.env.VUE_APP_API_BASE_URL + '/sales/' + payload.id, payload)
                .then( async response => {
                    commit('EDIT_SALES', response.data)
                    commit('clearMessage')
                    commit('setLoading', false)
                    await   dispatch('loadSales')
                    commit('setMessage', 'Ozgartirildi')
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        console.log(error)
                        commit('setMessage')
                    }
                });
        },
        // loadPremium({commit}){
        //     commit('setLoading', true)
        //     axios.get(process.env.VUE_APP_API_BASE_URL + '/get-premium-customer', )
        //         .then(result => result.data)
        //         .then(async premium => {
        //             await commit('SET_PREMIUM_CUSTOMER', premium)
        //         })
        //     commit('setLoading', false)
        //     commit('clearMessage')
        // },
        getSalesId({commit}, payload) {
            axios.get(process.env.VUE_APP_API_BASE_URL + "/sales/" + payload.id).then(response => {
                commit('GET_SALES_ID', response.data)
            })
        },
        addSales({commit, dispatch}, payload) {
            commit('setLoading', true)
            axios.post(process.env.VUE_APP_API_BASE_URL+ '/sales', payload)
                .then(result => {
                    commit('ADD_SALES', payload)
                    commit('CLEAN_SALES')
                    commit('setMessage', 'Mahsulot qoshildi')
                  dispatch('loadSalesForCommit')
                    commit('setLoading', false)
                    return result
                })

        },
        addClient({commit, dispatch}, payload) {
            axios.post(process.env.VUE_APP_API_BASE_URL + '/customers', payload)
                .then(result => {
                    commit('ADD_CLIENT', payload)
                    dispatch('loadCustomers')
                    commit('setMessage', 'Client qoshildi')
                    return result
                })
        },
        loadSales({commit}, payload) {
            commit('setLoading', true)
            axios.get(process.env.VUE_APP_API_BASE_URL + '/sales', {
                params: {
                    date: payload
                }
            })
                .then(result => result.data)
                .then(async sales => {
                   await commit('SET_SALES', sales)
                })
            commit('setLoading', false)
            commit('clearMessage')
        },
         async  loadSalesForCommit({commit}) {
            commit('setLoading',true)
            axios.get(process.env.VUE_APP_API_BASE_URL + '/sales')
                .then(result => result.data)
                .then(sales => {
                    commit('SET_SALES', sales)
                    commit('setLoading',false)
                })
            commit('clearMessage')
        },
    },
    getters: {
        sales(state) {
            return state.sales
        },
        fieldsSales(state) {
            return state.fieldsSales
        },
        fieldsAdd(state) {
            return state.fieldsAdd
        },
        clientAdd(state) {
            return state.clientAdd
        },
        salesPaidTotal: state => state.sales.reduce((acc, val) => acc + val.paid ,0),
        salesDeptTotal: state => state.sales.reduce((acc, val) => acc + val.dept ,0),
        total: state => state.fieldsAdd.amount * state.fieldsAdd.price,
        dept: state => state.fieldsAdd.total_price - state.fieldsSales.paid,
        premium_customer: state => state.premium_customer
    }
}
