<template>
  <div>
    <div class="d-flex justify-center align-center">
      <img class="mt-16" style="width: 10rem;" src="@/assets/nodata/no_data.svg" alt="no data">
    </div>
    <div class="d-flex justify-center">
      <h1>Ma'lumotlar yo'q!</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoDate"
}
</script>

<style scoped>

</style>
