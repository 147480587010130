<template>
  <div>
    <v-navigation-drawer app width="100" class="bgNone">
      <div class="text-center logoDiv">
        <div>
          <navigation></navigation>
        </div>
      </div>
    </v-navigation-drawer>

    <v-app-bar app elevation="0" height="100" class="" color="white">
        <div class="pa-5 d-flex justify-space-between align-center w-100 flex-wrap">

          <div class="d-flex align-center">
              <v-app-bar-nav-icon @click="drawerNav = !drawerNav" 
                class="hidden-md-and-up"></v-app-bar-nav-icon>

              <img class="ma-2" src="@/assets/navabarIcons/ticket-expired.png" alt="">

              <div class="d-flex align-start flex-column justify-center ml-2">
                <h2>Mijozlar bazasi</h2>
                <p>Bizdan xarid qilgan mijozlar ma’lumoti</p>
              </div>
          </div>

          <v-spacer></v-spacer>
          <div class="d-flex">

            <router-link to="/seller/sellers">
              <v-btn class="mr-8 mt-2" style="opacity: .8;" elevation="2" color="primary">Sotuvchi qo'shish
              </v-btn>
            </router-link>

            <v-text-field prepend-inner-icon="mdi-magnify" outlined
              dense class="mt-1" v-model="search" hide-details
              label="Qidiruv..."></v-text-field>
          </div>
        </div>
    </v-app-bar>

    <div class="content__main">
        <v-col md="12" sm="12" style="background: #FCFDFF!important;">

          <v-card v-if="!(Object.keys(customers).length === 0)" elevation="0"
            style="background: #FCFDFF!important;">
            
            <v-data-table
              :items="customers"
              hide-default-header
              hide-default-footer
            >
              <template v-slot:header>
                <v-list-item class="d-flex justify-space-between mt-2 w-100">
                    <v-list-item-title class="text--secondary">Ism</v-list-item-title>
                    <v-list-item-title class="text--secondary">Karta raqami</v-list-item-title>
                    <v-list-item-title class="text--secondary">Telefon raqami</v-list-item-title>
                    <v-list-item-title class="text--secondary">Umumiy xaridi</v-list-item-title>
                </v-list-item>
              </template>

              <template v-slot:item="{ item, index }">
                <v-list-item-group>
                  <v-list-item @click="openWindow(item.id)">
                      <v-list-item-icon :class="{'circleAvatar':index % 2 ===1,'circleAvatar1':index % 2 ===0,'circleAvatar2':index % 3 ===1,}">
                        <span> {{ item.name[0] }} </span>
                      </v-list-item-icon>
                      <v-list-item-title>
                        <span>{{ item.name }}</span>
                      </v-list-item-title>
                      <v-list-item-title class="font-weight-medium">
                        {{ item.card_number }}
                      </v-list-item-title>
                      <v-list-item-title class="text--secondary">
                        <span class="font-weight-medium">{{ item.phone_number }}</span>
                      </v-list-item-title>
                      <v-list-item-title class="text--secondary">
                        <span class="font-weight-medium">{{ item.sales_sum_amount }}</span>
                        <span> tonna</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
              </template>
            </v-data-table>


            <div class="mx-24">
              <v-pagination
                v-model="customersPagination.current"
                :length="customersPagination.total"
                :total-visible="7"
                @input="onCustomersPageChange"
                color="#507BE8"
                class="mt-6"
              ></v-pagination>
            </div>
          </v-card>

          <Nodata v-else></Nodata>

        </v-col>

        <v-row justify="center">
          <v-dialog v-model="updateClientModal" max-width="400"
                    style="z-index: 999999999">
            <v-card class="pa-1" style="border-radius: 14px;">
              <v-list-item class="mt-2">
                <div class="d-flex">
                  <img src="@/assets/IconAvatar.svg" alt="">
                  <p class="mt-3 ml-3 font-weight-medium">O'zgatirish</p>
                </div>
                <v-spacer></v-spacer>
                <v-btn elevation="0" icon @click="updateClientModal=false"><img src="@/assets/xExit.svg" alt="">
                </v-btn>
              </v-list-item>
              <div class="pa-2">
                <v-divider justify="center" style="color: #EAECF3;"></v-divider>
              </div>
              <v-form
                ref="form"
                lazy-validation
              >
                <div class="pa-3">
                  <div>
                    <p class="text--secondary">Ism</p>
                    <v-text-field style=" padding-top: 0px;margin-top: 0px;" class="font-weight-medium"
                                  :rules="[v => !!v || 'Ism majburiy']"
                                  v-model="fields.name"><span class="text--secondary ml-2">so’m</span>
                    </v-text-field>
                  </div>
                  <div>
                    <p class="text--secondary">Karta raqami</p>
                    <v-text-field style="padding-top: 0px;margin-top: 0px;" class="font-weight-medium"
                                  :rules="[v => (v && v.length === 5) || 'Karta raqamingiz 5 ta raqamdan iborat bo`lsin',]"
                                  v-model="fields.card_number"><span
                      class="text--secondary ml-2">so’m</span>
                    </v-text-field>
                  </div>
                  <div>
                    <p class="text--secondary">Telefon raqami</p>
                    <v-text-field style=" padding-top: 0px;margin-top: 0px;" class="font-weight-medium"
                                  :counter="13"
                                  :rules="[v => (v && v.length <=13) || 'Telefon raqami talab qilinadi']"
                                  v-model="fields.phone_number"><span
                      class="text--secondary ml-2">so’m</span>
                    </v-text-field>
                  </div>

                </div>
                <div class="text-center">
                  <v-btn
                    color="error"
                    class="mr-4"
                    style="border-radius: 14px;width: 150px; height: 50px"
                    text
                    @click="updateClientModal=false"
                  >
                    Bekor qilish
                  </v-btn>

                  <v-btn
                    color="primary"
                    class="mr-4 "
                    large
                    elevation="0"
                    style="background: #507BE8;border-radius: 14px;width: 150px; height: 50px"
                    :loading="loading"
                    @click="updateCustomer()"
                  >
                    O'zgartirish
                  </v-btn>
                </div>
              </v-form>

              <v-container>
              </v-container>
            </v-card>
          </v-dialog>

        </v-row>

        <v-dialog v-model="addNewCustomerModal" width="500">
          <template v-slot:activator="{ on, attrs }">
            <img v-bind="attrs" v-on="on" style="position: fixed; right: 2%; bottom: 6%; width: 50px; z-index: 999;" src="@/assets/add-square.svg" alt="">
          </template>
          <v-card class="pa-5">
            <div class="d-flex">
              <div><p class="mt-3 ml-3 font-weight-bold">Yangi mijoz qo'shish</p></div>
              <v-spacer></v-spacer>
              <v-btn elevation="0" icon @click="addNewCustomerModal=false"><img
                src="@/assets/xExit.svg" alt="">
              </v-btn>
            </div>

            <v-form
              ref="form"
              lazy-validation
            >
              <div class="pa-3">
                <div>
                  <p class="text--secondary">Ism</p>
                  <v-text-field style=" padding-top: 0px;margin-top: 0px;" class="font-weight-medium"
                                :rules="[v => !!v || 'Ism majburiy']"
                                v-model="fields.name"><span class="text--secondary ml-2">so’m</span>
                  </v-text-field>
                </div>
                <div>
                  <p class="text--secondary">Karta raqami</p>
                  <v-text-field style="padding-top: 0px;margin-top: 0px;" class="font-weight-medium"
                                :rules="[v => (v && v.length === 5) || 'Karta raqamingiz 5 ta raqamdan iborat bo`lsin',]"
                                v-model="fields.card_number"><span
                    class="text--secondary ml-2">so’m</span>
                  </v-text-field>
                </div>
                <div>
                  <p class="text--secondary">Telefon raqami</p>
                  <v-text-field style=" padding-top: 0px;margin-top: 0px;" class="font-weight-medium"
                                :counter="13"
                                :rules="[v => (v && v.length <=13) || 'Telefon raqami talab qilinadi']"
                                v-model="fields.phone_number"><span
                    class="text--secondary ml-2">so’m</span>
                  </v-text-field>
                </div>

              </div>
            </v-form>


            <v-card-actions>
              <div class="d-flex justify-space-around align-center w-100">
                <v-btn
                  color="error"
                  style="border-radius: 14px;"
                  text
                  large
                  class="w-35"
                  @click="addNewCustomerModal=false">
                  Bekor qilish
                </v-btn>

                <v-btn
                  color="primary"
                  large
                  elevation="0"
                  @click="addNewCustomer"
                  class="w-35"
                  style="background: #507BE8;border-radius: 14px;">
                  Qo'shish
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
  </div>
</template>

<script>
import navigation from '../../components/NavigationDrawerSeller'
import {mapGetters} from "vuex";
import Nodata from "../../components/base/NoDate";

export default {
  name: "Clients",
  data() {
    return {
      updateClientModal: false,
      addNewCustomerModal: false,
      menu: '',
      customers: [],
      customersPagination: {
        current: 1,
        total: 0
      },
      search: '',
      fields: {
        card_number: '',
        phone_number: '',
        name: '',
      },
      editCustomerId: '',
    }
  },

  methods: {
    addNewCustomer() {
      this.$axios
        .post("/customers", this.fields)
        .then(response => {
          //Update table
          this.getCustomers();
          //Close modal
          this.addNewCustomerModal = false;
          //Success notification message
          this.$toast.success(response.data.message);
          this.fields = {};
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            //Error notification message
            this.$toast.error("Пожалуйста, попробуйте еще раз!");
          }
        });
    },

    getCustomers() {
      let searchUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.search) {
        searchUrl = '&search=' + this.search;
      } else {
        searchUrl = '';
      }
      this.$axios.get('/customers?page=' + this.customersPagination.current + '&cvartal=' + cvartal + searchUrl).then(response => {
        this.customers = response.data.data;
        this.customersPagination.current = response.data.current_page;
        this.customersPagination.total = response.data.last_page;

        // const usersByLikes = this.customers.map(item => {
        //   const container = {};
        //
        //   container["id"] = item.id;
        //   container["name"] = item.name;
        //   container["card_number"] = item.card_number;
        //   container["phone_number"] = item.phone_number;
        //   container["paid"] = this.getSumOfProductAmount(item.sales);
        //
        //   return container;
        // });
        //
        // this.customers = usersByLikes.sort(function(a, b){return b.paid - a.paid});
        // console.log(this.customers.sort(function(a, b){return b.paid - a.paid}))

      }).catch(error => {
        console.log(error)
      });
    },

    onCustomersPageChange() {
      this.getCustomers();
    },

    getSumOfProductAmount(arr) {
      let sum = 0;
      arr.forEach((el) => {
        sum += el.amount;
      })
      return sum;
    },

    updateCustomer() {
      this.$axios
        .put("/customers/" + this.editCustomerId, this.fields)
        .then(response => {
          this.getCustomers();
          this.updateClientModal = false;
          //Success notification message
          this.$toast.success(response.data.message);
          this.fields = {};
          this.editCustomerId = ''
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            //Error notification message
            this.$toast.error("Iltimos, qayta urinib ko'ring!");
          }
        });
    },

    openWindow(id) {
      this.updateClientModal = true;
      this.editCustomerId = id;
      if (this.editCustomerId) {
        this.$axios.get("/customers/" + this.editCustomerId).then(response => {
          this.fields = response.data
        });
      }
    },

    routeLinks(item) {
      this.routeIcons.forEach(e => {
        e.active = false
      })
      item.active = true
      this.$router.push(item.path)
    },

  },

  mounted() {
    this.getCustomers();
  },

  computed: {
    ...mapGetters(['loading', 'routeIcons']),
    loading() {
      return this.$store.getters.loading
    },

    'onSearch'() {
      return this.search;
    },

    drawerNav: {
        get: function() {
            return this.$store.state.common.drawerNav
        },
        set: function(newValue) {
            return this.$store.state.common.drawerNav = newValue
        }
    },
  },

  watch: {
    onSearch() {
      this.customersPagination.current = 1;
      this.customersPagination.current = 1;
      this.getCustomers();
    },
  },
  components: {
    navigation, Nodata
  }
}
</script>

<style scoped>

</style>
