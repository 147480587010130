import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default {
    state: {
        drawerNav: false,
        routeIconsSeller: [
            {
                icon: require('@/assets/navigationDrawerIcons/receive-square.svg'),
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/receive-square.svg'),
                path: '/seller/enter',
                active: false,

            },
            {
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/send-square.svg'),
                icon: require('@/assets/navigationDrawerIcons/send-square.svg'),
                path: '/seller/exit',
                active: false,
            },
            {
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/layer.svg'),
                path: '/seller/storage',
                active: false,
            },
            {
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/dollar-square.svg'),
                path: '/seller/finance',
                active: false,
            },
            {
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/people.svg'),
                path: '/seller/sellers',
                active: false,
            },
            {
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/group.svg'),
                path: '/seller/debts',
                active: false,
            },
        ],

        routeIconsNav :[
            {
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/home-2.svg'),
                path: '/dashboard',
                active: false,
            },
            {
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/receive-square.svg'),
                path: '/enter',
                active: false,

            },
            {
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/layer.svg'),
                path: '/storage',
                active: false,
            },
            {
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/send-square.svg'),
                path: '/exit',
                active: false,
            },
            {
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/group.svg'),
                path: '/debts',
                active: false,
            },
            {
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/dollar-square.svg'),
                path: '/finance',
                active: false,
            },
            {
                iconUnactive: require('@/assets/navigationDraweIconsNotActive/people.svg'),
                path: '/clients',
                active: false,
            },

        ],
    },
    mutations: {

    },
    actions: {

    },
    getters: {
        routeIconsSeller(state){
            return state.routeIconsSeller
        },
        routeIconsNav(state){
            return state.routeIconsNav
        }


    }
}
