import Vue from 'vue'
import Vuex from 'vuex'
import sales from "./modules/sales";
import customers from "./modules/customers";
import common from "./modules/common";
import share from "./modules/share";
import debtor from "./modules/debtor";
import storage from "./modules/storage";
import income from "./modules/income";
import finance from "./modules/finance";
import dates from "./modules/dates";
import auth from "./modules/auth";

Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        sales, common, customers, share, debtor, storage , income ,finance , dates ,auth
    }
})
