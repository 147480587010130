<template>
  <div class="app">
    <div :is="layout"></div>
    <message></message>
    <errorMessage></errorMessage>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import message from './components/Message';
import errorMessage from './components/errorMessage';

export default {
  head: {
    title: {
      inner: 'Magnat',
      complement: "CRM"
    },
    link: [
      { rel: 'icon', href: require('./assets/magnat-logo.png'), sizes: '16x16', type: 'image/png' },
    ]
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['routeIcons', 'routeIconsSeller']),
    layout() {
      const layoutName = this.$route.meta.layout || 'DefaultLayout'
      return () => import(`./layouts/${layoutName}.vue`)
    },
  },
  mounted() {
    // const currentRouter = this.$route.fullPath
    // const currentObj = this.routeIcons.find(el => el.path == currentRouter)
    // console.log(this.routeIcons)
    // this.routeIcons.forEach(el => {
    //   el.active = false;
    // })
    // const currentRouter1 = this.$route.fullPath;
    // // eslint-disable-next-line no-unused-vars
    // const currentObj1 = this.routeIconsSeller.find(el => el.path == currentRouter1)
    // this.routeIcons.forEach(el => {
    //   el.active = false;
    // })
    // return currentObj.active = true

  },
  components: {
    message, errorMessage
  }

}
</script>


<style>
#app {
  background: #FCFDFF !important;
  position: relative;
}
</style>
