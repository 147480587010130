<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      max-width="400"
      style="position: absolute;margin-bottom: 280px;"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar class="avatar" v-bind="attrs" v-on="on">
          <img src="../assets/logoPerson.svg">
        </v-avatar>
      </template>

      <v-card>
        <v-container>
          <h2>Tanlang</h2>
          <v-row class="mt-5">
            <v-col v-show="!this.$route.fullPath.includes('/seller')">
              <div class="d-flex" style="border: 2px solid #EDF0F5;box-sizing: border-box;border-radius: 18px;">
                <p class="text--secondary font-weight-bold ml-5 mt-5 d-flex">Ma'lumotlarizni o'zgartirish 😃</p>
                <v-radio-group v-model="options" class="ml-4">
                  <v-radio value="edit"></v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-col>
              <div class="d-flex" style="border: 2px solid #EDF0F5;box-sizing: border-box;border-radius: 18px;">
                <p class="text--secondary ml-5  font-weight-bold mt-5 d-flex">Profildan chiqish 😩</p>
                <v-radio-group v-model="options" class="ml-4">
                  <v-radio value="exit"></v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>

        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mr-4 rounded"
            large
            elevation="0"
            :disabled="!disableButtonSelect"
            @click="select"
          >
            Tanlash
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEdit"
      width="500"
      style="position: absolute;margin-bottom: 280px;"
    >
      <v-card>
        <v-container>
          <h2>Ma'lumotlarizni o'zgartirish</h2>
          <br>
          <!--          <v-item-group>-->
          <!--            <p class="font-weight-medium">Ism va familiya</p>-->
          <!--            <v-text-field placeholder="Misol: Abror Azizov" :rules="[  v => !!v || 'Ism va familiya majburiy']"-->
          <!--                          v-model="email" outlined></v-text-field>-->
          <!--          </v-item-group>-->


          <v-item-group>
            <p class="font-weight-medium">Email:</p>
            <v-text-field v-model="email" :rules="[  v => !!v || 'Email majburiy']" placeholder="Misol: test@gmail.com"
                          outlined></v-text-field>
          </v-item-group>

          <v-item-group>
            <p class="font-weight-medium">Parol:</p>
            <v-text-field v-model="password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show ? 'text' : 'password'"
                          @click:append="show = !show" :rules="[  v => !!v || 'Parol majburiy']"
                          placeholder="Misol: 12345" outlined></v-text-field>
          </v-item-group>

        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mr-4 "
            large
            elevation="0"
            style="background: #507BE8;border-radius: 14px;width: 150px; height: 50px"
            @click="editUser"
          >
            O'zgartirish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "AvatarModal",
  data() {
    return {
      dialog: false,
      dialogEdit: '',
      email: '',
      show: false,
      options: '',
      password: '',
      fieldsUser: {name: '', password: '', email: '', id: ''},
      name: 'Azizbek'
    }
  },
  computed: {
    disableButtonSelect() {
      return this.options
    }
  },
  methods: {
    select() {
      if (this.options === 'exit') {
        axios.get(process.env.VUE_APP_API_BASE_URL + "/logout").then(response => {
          localStorage.clear();
          this.$router.push('/')
          return response;
        });
      } else {
        this.dialogEdit = true
        this.dialog = false
      }
    },
    editUser() {
      this.fieldsUser.name = this.name
      this.fieldsUser.password = this.password
      this.fieldsUser.email = this.email
      if (this.$route.fullPath.includes('/seller')) {
        this.fieldsUser.id = 2
      } else {
        this.fieldsUser.id = 1
      }
      this.$store.dispatch('updateUser', this.fieldsUser)
    }
  }
}
</script>

<style scoped>
.avatar {
  margin-top: 40px;
}

@media (min-width: 1700px) {
  .avatar {
    margin-top: 160px;
  }
}
</style>
