<template>
  <div class="d-flex justify-center align-center">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "ProgressCircular"
}
</script>

<style scoped>

</style>
