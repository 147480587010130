<template>
  <div>
    <template v-if="errorMessage">
      <v-app app>
        <div class="text-center">
          <v-snackbar
            :timeout="2000"
            @input="closeMessage"
            :value="true"
            right
            color="error"
          >
            {{errorMessage}}

            <template v-slot:action="{ attrs }">
              <v-btn
                color=""
                text
                v-bind="attrs"
                @click="closeMessage"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-app>
    </template>
  </div>
</template>

<script>
export default {
  name: "Message",
  data(){
    return {
    }
  },
  methods:{
    closeMessage(){
      this.$store.dispatch('clearMessage')
    }
  },
  computed:{
    errorMessage(){
      return this.$store.getters.errorMessage
    }
  }
}
</script>

<style scoped>

</style>
