/* eslint-disable */
import axios from "axios";

axios.defaults.withCredentials = true;

let token = localStorage.getItem('token');

const instance = axios.create({
    // baseURL: process.env.VUE_APP_API_BASE_URL || "http://127.0.0.1:8000/api",
    baseURL: process.env.VUE_APP_API_BASE_URL || "https://magnat-crm.uz/api",
    timeout: 60000,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + token
    },
});


//send token
instance.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);


//404 or 401 pages use this response
instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    },
);

export default instance;
