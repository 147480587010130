import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)


export default {
    state: {
        debtors: [],
        loading: true,
        date: '',
        debtorsId:[],
        sendPayment:{}
    },
    mutations: {
        SET_DEBTORS(state, debtors) {
            state.debtors = debtors
        },
        GET_DEBT_ID(state,payload){
            state.debtorsId=payload
        },
        SEND_PAYMENT(state,payload){
            state.sendPayment.payment=payload.payment
            state.sendPayment.sale_id=payload.sale_id
        }
    },
    actions: {
        loadData({commit}) {
            commit('setLoading',true)
            axios.get(process.env.VUE_APP_API_BASE_URL + '/customers').then((response) => {
                // console.log(response.data, this)
                commit('SET_DEBTORS', response.data)
                commit('setLoading', false)
            })
        },
        getDebtId({commit}, payload) {
            axios.get(process.env.VUE_APP_API_BASE_URL+ "/customers/" + payload.id).then(response => {
                commit('GET_DEBT_ID', response.data)
            })
        },
        sendPayment({commit},payload){
            commit('setLoading',true)
            axios.post(process.env.VUE_APP_API_BASE_URL+'/sale-payment',payload)
                .then(result=>{
                    commit('SEND_PAYMENT',payload)
                    commit('setLoading',false)
                    commit('setMessage','Muffaqiyatli qoshildi')
                    return result
                })
                .catch(error=>{
                    console.log(error)
                })
        }

    },
    getters: {
        debtors(state) {
            return state.debtors
        },
        debtorsId(state){
            return state.debtorsId
        },
    }
}
