<template>
  <div>
    <v-row>
      <v-navigation-drawer app width="100" class="bgNone">
        <div class="text-center logoDiv">
          <div>
            <navigation></navigation>
          </div>
        </div>
      </v-navigation-drawer>

      <v-app-bar app elevation="0" height="100" class="" color="white">
          <div class="pa-5 d-flex justify-space-between align-center w-100 flex-wrap">


            <div class="d-flex align-center">
              <v-app-bar-nav-icon @click="drawerNav = !drawerNav" 
                class="hidden-md-and-up"></v-app-bar-nav-icon>

              <img class="ma-2" src="@/assets/convert-3d-cube.svg" alt="">

              <div class="d-flex align-start flex-column justify-center ml-2">
                <h2>Sotuvlar</h2>
                <span>Sotilgan mahsulotlar arxivi</span>
              </div>
            </div>

            <v-spacer></v-spacer>

            <div class="d-flex align-center">

              <v-menu offset-y content-class="elevation-1 rounded-lg">

                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon plain v-bind="attrs" v-on="on" class="mr-4" @click="openReminder">
                    <v-badge color="blue" :content="dataReminder.length">
                      <svg width="24" height="28" viewBox="0 0 24 28" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.0201 6.91C8.71009 6.91 6.02009 9.6 6.02009 12.91V15.8C6.02009 16.41 5.76009 17.34 5.45009 17.86L4.30009 19.77C3.59009 20.95 4.08009 22.26 5.38009 22.7C9.69009 24.14 14.3401 24.14 18.6501 22.7C19.8601 22.3 20.3901 20.87 19.7301 19.77L18.5801 17.86C18.2801 17.34 18.0201 16.41 18.0201 15.8V12.91C18.0201 9.61 15.3201 6.91 12.0201 6.91Z"
                          stroke="#91A3B8" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                        <path
                          d="M13.8699 7.2C13.5599 7.11 13.2399 7.04 12.9099 7C11.9499 6.88 11.0299 6.95 10.1699 7.2C10.4599 6.46 11.1799 5.94 12.0199 5.94C12.8599 5.94 13.5799 6.46 13.8699 7.2Z"
                          stroke="#91A3B8" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"/>
                        <path
                          d="M15.02 23.06C15.02 24.71 13.67 26.06 12.02 26.06C11.2 26.06 10.44 25.72 9.90002 25.18C9.36002 24.64 9.02002 23.88 9.02002 23.06"
                          stroke="#91A3B8" stroke-width="1.5" stroke-miterlimit="10"/>
                      </svg>
                    </v-badge>
                  </v-btn>
                </template>

                <v-list style="background: white" v-show="reminderOpen">
                  <v-list-item-group v-if="!(Object.keys(dataReminder).length === 0)"
                                      style="width: 400px; height: 300px;background: white" v-model="selected"
                                      active-class="primary--text" multiple>
                    <v-col style="background: white; flex-wrap: nowrap; border-bottom: 1px black solid;"
                            v-for="(item,index) in dataReminder"
                            :key="index">
                      <p class="font-weight-medium pl-2 pr-2">
                        <span class="font-weight-bold">{{ item.customer.name }}</span> | <span
                        v-if="item.total_price - getSumOfPayments(item.payments) == 0"
                        style="color: #4caf50;font-weight: 600;">{{
                          formatPrice(item.total_price - getSumOfPayments(item.payments))
                        }}</span>
                        <span v-if="item.total_price - getSumOfPayments(item.payments) != 0"
                              style="color: #f44336;font-weight: 600;">{{
                            formatPrice(item.total_price - getSumOfPayments(item.payments))
                          }}</span>
                        so'm
                        | {{ item.customer.phone_number }}
                        | {{ item.payment_deadline | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                      </p>
                    </v-col>
                  </v-list-item-group>
                  <v-list-item-group v-else style="width: 400px; height: 300px;background: white" v-model="selected"
                                      active-class="primary--text" multiple>
                    <v-col style="background: white; flex-wrap: nowrap;">
                      <p class="font-weight-medium pl-2 pr-2">
                        Ma'lumotlar yo'q 😩
                      </p>
                    </v-col>
                  </v-list-item-group>
                </v-list>

              </v-menu>

              <v-text-field prepend-inner-icon="mdi-magnify" outlined dense
                class="mt-1" hide-details
                v-model="search" label="Qidiruv..."></v-text-field>
            </div>
          </div>
      </v-app-bar>

    </v-row>

    <div class="content__main">
      <div class="mt-2">
        <v-tabs v-model="tab" class="bgNone" background-color="transparent">
          <div class="d-flex justify-space-between w-100 flex-wrap">

            <div class="d-flex mb-10">
              <v-tab href="#tab-1" @click="dailySales()" style="color: #91A3B8;font-size: 15px">
                BUGUNGI SOTUV
              </v-tab>
              <v-tab href="#tab-2" @click="setProductType(2)" style="color: #91A3B8;font-size: 15px">
                KONVERT QOP
              </v-tab>
              <v-tab href="#tab-3" @click="setProductType(3)" style="color: #91A3B8;font-size: 15px">
                ODDIY QOP
              </v-tab>
              <v-tab href="#tab-4" @click="setProductType(1)" class="tabBtn" style="color: #91A3B8;font-size: 15px">
                RASSIPNOY
              </v-tab>
            </div>

            <div class="d-flex">
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" left
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date" placeholder="Sanani tanlang"
                    append-icon="mdi-calendar" outlined 
                    readonly v-bind="attrs" v-on="on" style="border-radius: 14px"></v-text-field>
                </template>
                <v-date-picker
                  v-model="date" :min="getMinDate" :max="getMaxDate"
                  scrollable
                  range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="date = []">
                    O'chirish
                  </v-btn>


                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>

                </v-date-picker>
              </v-menu>
            </div>

          </div>

        </v-tabs>


        <v-tabs-items class="mt-2" v-model="tab" style="background: #FCFDFF!important;">

          <v-tab-item :value="'tab-1'" style="right: 10px">
            <div v-if="!(Object.keys(dailySale).length === 0)">
              <v-col md="12" sm="12" style="background: #FCFDFF!important;">
               <v-data-table
                  :items="dailySale"
                  hide-default-header
                  hide-default-footer
                >
                  <template v-slot:header>
                    <v-list-item class="d-flex justify-space-between mt-2 w-100">
                        <v-list-item-title class="text--secondary">Kimga</v-list-item-title>
                        <v-list-item-title class="text--secondary ml-4">Massa</v-list-item-title>
                        <v-list-item-title class="text--secondary">Narxi</v-list-item-title>
                        <v-list-item-title class="text--secondary">Umumiy summa</v-list-item-title>
                        <v-list-item-title class="text--secondary">Berildi</v-list-item-title>
                        <v-list-item-title class="text--secondary">Qoldi</v-list-item-title>
                        <v-list-item-title class="text--secondary">Sana</v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-slot:item="{ item, index }">
                    <v-list-item-group>
                        <v-list-item>
                          <!-- @click="openDeleteConvertTinSale(item.id)" -->
                          <v-list-item-icon
                            :class="{'circleAvatar':index % 2 ===1,'circleAvatar1':index % 2 ===0,'circleAvatar2':index % 3 ===1,}">
                            <span> {{ item.customer.name[0] }}</span>
                          </v-list-item-icon>
                          <v-list-item-title>
                            <span>{{ item.customer.name.substring(20, -1) }} </span>
                          </v-list-item-title>
                          <v-list-item-title class="font-weight-medium ml-4">
                            <span class=" text--black">{{ item.amount }}</span><span
                            class="text--secondary ml-1">tonna</span>
                          </v-list-item-title>
                          <br>
                          <v-list-item-title v-if="item.quantity != null" class="font-weight-medium">
                            <span class="text--black">{{ item.quantity }}</span><span
                            class="text--secondary ml-1">dona</span>
                          </v-list-item-title>

                          <v-list-item-title class="font-weight-medium">
                            <span class=" text--black">{{ formatPrice(item.price) }}</span><span
                            class="text--secondary ml-1">so’m</span>
                          </v-list-item-title>
                          <v-list-item-title class="font-weight-medium">
                            <span class=" text--black">{{ formatPrice(item.total_price) }}</span><span
                            class="text--secondary ml-1">so’m</span>
                          </v-list-item-title>
                          <v-list-item-title class="font-weight-medium green--text">
                              <span class="text--black">{{
                                  formatPrice(getSumOfPayments(item.payments))
                                }}</span><span
                            class="text--secondary ml-1">so’m</span>
                          </v-list-item-title>
                          <v-list-item-title class="font-weight-medium red--text">
                              <span>{{
                                  formatPrice(item.total_price - getSumOfPayments(item.payments))
                                }}</span><span
                            class="text--secondary ml-1">so’m</span>
                          </v-list-item-title>
                          <v-list-item-title class="font-weight-medium">
                              <span class="text--black">
                                {{ item.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                              </span>
                          </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                   
                  </template>
                </v-data-table>


                <div class="mx-24">
                  <v-pagination
                    v-model="dailySalePagination.current"
                    :length="dailySalePagination.total"
                    :total-visible="7"
                    @input="onDailySalesPageChange"
                    color="#507BE8"
                    class="my-4">
                  </v-pagination>
                </div>

                <!--  Confirm delete modal  -->
                <template>
                  <v-row justify="center">
                    <v-dialog v-model="deleteModal" persistent max-width="450">
                      <v-card class="pa-4">
                        <div class="d-flex">
                          <div><p class="mt-3 ml-3 font-weight-bold">Sotuvni o'chirish</p></div>
                          <v-spacer></v-spacer>
                          <v-btn elevation="0" icon @click="deleteModal=false"><img
                            src="@/assets/xExit.svg" alt="">
                          </v-btn>
                        </div>
                        <v-card-text class="text-center font-weight-bold" style="font-size: 18px;">
                          Quyidagi kiritmani o'chirgandan so'ng uni qayta tiklay olmaysiz! <br>
                          <img style="width: 50px; margin-top: 20px;" src="@/assets/trash.svg" alt="trash">
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" outlined text @click="deleteSaleConvertTin('only')">
                            Sotuvlardan o'chirish
                          </v-btn>
                          <v-btn color="red" outlined text @click="deleteSaleConvertTin('fully')">
                            Skladdan o'chirish
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </template>
              </v-col>
            </div>
            <Nodata v-else></Nodata>
          </v-tab-item>

          <v-tab-item :value="'tab-2'" style="right: 10px">
            <div v-if="!(Object.keys(convertTinSales).length === 0)">
              <v-col md="12" sm="12" style="background: #FCFDFF!important;">
                <v-data-table
                  :items="convertTinSales"
                  hide-default-header
                  hide-default-footer
                >
                  <template v-slot:header>
                    <v-list-item class="d-flex justify-space-between mt-2 w-100">
                        <v-list-item-title class="text--secondary">Kimga</v-list-item-title>
                        <v-list-item-title class="text--secondary ml-4">Massa</v-list-item-title>
                        <v-list-item-title class="text--secondary">Narxi</v-list-item-title>
                        <v-list-item-title class="text--secondary">Umumiy summa</v-list-item-title>
                        <v-list-item-title class="text--secondary">Berildi</v-list-item-title>
                        <v-list-item-title class="text--secondary">Qoldi</v-list-item-title>
                        <v-list-item-title class="text--secondary">Sana</v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-slot:item="{ item, index }">

                    <v-list-item-group>
                      <v-list-item @click="openDeleteConvertTinSale(item.id)" >
                        <v-list-item-title class="d-inline-flex align-center">
                        <v-list-item-icon :class="{'circleAvatar' : index % 2 === 1,'circleAvatar1' : index % 2 === 0,'circleAvatar2' : index % 3 === 1}">
                          <span>{{ item.customer.name[0] }}</span>
                        </v-list-item-icon>
                          <span>{{ item.customer.name.substring(20, -1) }} </span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium ml-4">
                          <span class=" text--black">{{ item.amount }}</span><span
                          class="text--secondary ml-1">tonna</span>
                          <br>
                          <span class=" text--black">{{ item.quantity }}</span><span
                          class="text--secondary ml-1">dona</span>
                        </v-list-item-title>

                        <v-list-item-title class="font-weight-medium">
                          <span class=" text--black">{{ formatPrice(item.price) }}</span><span
                          class="text--secondary ml-1">so’m</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium">
                          <span class=" text--black">{{ formatPrice(item.total_price) }}</span><span
                          class="text--secondary ml-1">so’m</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium green--text">
                            <span class="text--black">{{
                                formatPrice(getSumOfPayments(item.payments))
                              }}</span><span
                          class="text--secondary ml-1">so’m</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium red--text">
                            <span>{{
                                formatPrice(item.total_price - getSumOfPayments(item.payments))
                              }}</span><span
                          class="text--secondary ml-1">so’m</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium">
                            <span class="text--black">
                              {{ item.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                              <!-- {{ item.created_at | moment("add", "1 days","timezone", "Asia/Tashkent", "DD MMMM YYYY") }}-->
                            </span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                   
                  </template>
                </v-data-table>


                <div class="mx-24">
                  <v-pagination
                    v-model="convertTinSalesPagination.current"
                    :length="convertTinSalesPagination.total"
                    :total-visible="7"
                    @input="onConvertTinSalesPageChange"
                    color="#507BE8"
                    class="my-4">
                  </v-pagination>
                </div>
              </v-col>
            </div>
            <Nodata v-else></Nodata>
          </v-tab-item>

          <v-tab-item :value="'tab-3'" style="right: 10px">
            <div v-if="!(Object.keys(simpleTinSales).length === 0)">
              <v-col md="12" sm="12" style="background: #FCFDFF!important;">
                <v-data-table
                  :items="simpleTinSales"
                  class=""
                  hide-default-header
                  hide-default-footer
                >
                  <template v-slot:header>
                    <v-list-item class="d-flex justify-space-between mt-2 w-100">
                        <v-list-item-title class="text--secondary">Kimga</v-list-item-title>
                        <v-list-item-title class="text--secondary ml-4">Massa</v-list-item-title>
                        <v-list-item-title class="text--secondary">Narxi</v-list-item-title>
                        <v-list-item-title class="text--secondary">Umumiy summa</v-list-item-title>
                        <v-list-item-title class="text--secondary">Berildi</v-list-item-title>
                        <v-list-item-title class="text--secondary">Qoldi</v-list-item-title>
                        <v-list-item-title class="text--secondary">Sana</v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-slot:item="{ item, index }">

                    <v-list-item-group>
                      <v-list-item @click="openDeleteSimpleTinSale(item.id)">
                        <v-list-item-title class="d-inline-flex align-center">
                        <v-list-item-icon :class="{'circleAvatar' : index % 2 ===1,'circleAvatar1' : index % 2 === 0,'circleAvatar2' : index % 3 ===1}">
                          <span> {{ item.customer.name[0] }}</span>
                        </v-list-item-icon>
                          <span>{{ item.customer.name.substring(20, -1) }}</span>
                        </v-list-item-title>

                        <v-list-item-title class="font-weight-medium ml-4">
                          <span class="text--black">{{ item.amount }}</span><span
                          class="text--secondary ml-1">tonna</span>
                        <br>
                          <span class=" text--black">{{ item.quantity }}</span><span
                          class="text--secondary ml-1">dona</span>
                        </v-list-item-title>

                        <v-list-item-title class="font-weight-medium">
                          <span class=" text--black">{{ formatPrice(item.price) }}</span><span
                          class="text--secondary ml-1">so’m</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium">
                          <span class=" text--black">{{ formatPrice(item.total_price) }}</span><span
                          class="text--secondary ml-1">so’m</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium green--text">
                            <span class="text--black">{{
                                formatPrice(getSumOfPayments(item.payments))
                              }}</span><span
                          class="text--secondary ml-1">so’m</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium red--text">
                            <span>{{
                                formatPrice(item.total_price - getSumOfPayments(item.payments))
                              }}</span><span
                          class="text--secondary ml-1">so’m</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium">
                            <span class="text--black">
                              {{ item.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                              <!--                                          {{ item.created_at | moment("add", "1 days","timezone", "Asia/Tashkent", "DD MMMM YYYY") }}-->
                            </span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>


                   
                  </template>
                </v-data-table>
                
                <div class="mx-24">
                  <v-pagination
                    v-model="simpleTinSalesPagination.current"
                    :length="simpleTinSalesPagination.total"
                    @input="onTinSalesPageChange"
                    :total-visible="7"
                    color="#507BE8"
                    class="my-4">
                  </v-pagination>
                </div>
                <!--  Confirm delete modal  -->
                <v-dialog v-model="deleteModal" persistent max-width="450">
                  <v-card class="pa-4">
                    <div class="d-flex">
                      <div><p class="mt-3 ml-3 font-weight-bold">Sotuvni o'chirish</p></div>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" icon @click="deleteModal=false"><img
                        src="@/assets/xExit.svg" alt="">
                      </v-btn>
                    </div>
                    <v-card-text class="text-center font-weight-bold" style="font-size: 18px;">
                      Quyidagi kiritmani o'chirgandan so'ng uni qayta tiklay olmaysiz! <br>
                      <img style="width: 50px; margin-top: 20px;" src="@/assets/trash.svg" alt="trash">
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" outlined text @click="deleteSaleSimpleTin('only')">
                        Sotuvlardan o'chirish
                      </v-btn>
                      <v-btn color="red" outlined text @click="deleteSaleSimpleTin('fully')">
                        Skladdan o'chirish
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </div>
            <Nodata v-else></Nodata>
          </v-tab-item>

          <v-tab-item :value="'tab-4'" style="right: 10px">
            <div v-if="!(Object.keys(cementSales).length === 0)">
              <v-col md="12" sm="12" style="background: #FCFDFF!important;">

                <v-data-table
                  :items="cementSales"
                  hide-default-header
                  hide-default-footer
                >
                  <template v-slot:header>
                    <v-list-item class="d-flex justify-space-between mt-2 w-100">
                        <v-list-item-title class="text--secondary">Kimga</v-list-item-title>
                        <v-list-item-title class="text--secondary ml-4">Massa</v-list-item-title>
                        <v-list-item-title class="text--secondary">Narxi</v-list-item-title>
                        <v-list-item-title class="text--secondary">Umumiy summa</v-list-item-title>
                        <v-list-item-title class="text--secondary">Berildi</v-list-item-title>
                        <v-list-item-title class="text--secondary">Qoldi</v-list-item-title>
                        <v-list-item-title class="text--secondary">Sana</v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-slot:item="{ item, index }">
                    <v-list-item-group>
                      <v-list-item @click="openDeleteCementSale(item.id)">

                        <v-list-item-title class="d-inline-flex align-center">
                        <v-list-item-icon :class="{'circleAvatar' : index % 2 ===1,'circleAvatar1' : index % 2 === 0,'circleAvatar2' : index % 3 ===1}">
                          <span> {{ item.customer.name[0] }}</span>
                        </v-list-item-icon>
                          <span>{{ item.customer.name.substring(20, -1) }}</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium ml-4">
                          <span class="text--black">{{ item.amount }}</span><span
                          class="text--secondary ml-1">tonna</span>
                        </v-list-item-title>

                        <v-list-item-title class="font-weight-medium">
                          <span class=" text--black">{{ formatPrice(item.price) }}</span><span
                          class="text--secondary ml-1">so’m</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium">
                          <span class=" text--black">{{ formatPrice(item.total_price) }}</span><span
                          class="text--secondary ml-1">so’m</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium green--text">
                            <span class="text--black">{{
                                formatPrice(getSumOfPayments(item.payments))
                              }}</span><span
                          class="text--secondary ml-1">so’m</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium red--text">
                            <span>{{
                                formatPrice(item.total_price - getSumOfPayments(item.payments))
                              }}</span><span
                          class="text--secondary ml-1">so’m</span>
                        </v-list-item-title>
                        <v-list-item-title class="font-weight-medium">
                            <span class="text--black">
                              {{ item.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                            </span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                   
                  </template>
                </v-data-table>
                
                <div class="mx-24">
                  <v-pagination
                    v-model="cementSalesPagination.current"
                    :length="cementSalesPagination.total"
                    :total-visible="7"
                    @input="onCementSalesPageChange"
                    color="#507BE8"
                    class="my-4"
                  ></v-pagination>
                </div>
                <v-dialog v-model="deleteModal" persistent max-width="450">
                  <v-card class="pa-4">
                    <div class="d-flex">
                      <div><p class="mt-3 ml-3 font-weight-bold">Sotuvni o'chirish</p></div>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" icon @click="deleteModal=false"><img
                        src="@/assets/xExit.svg" alt="">
                      </v-btn>
                    </div>
                    <v-card-text class="text-center font-weight-bold" style="font-size: 18px;">
                      Quyidagi kiritmani o'chirgandan so'ng uni qayta tiklay olmaysiz! <br>
                      <img style="width: 50px; margin-top: 20px;" src="@/assets/trash.svg" alt="trash">
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" outlined text @click="deleteSaleCement('only')">
                        Kiritmalardan o'chirish
                      </v-btn>
                      <v-btn color="red" outlined text @click="deleteSaleCement('fully')">
                        Skladdan o'chirish
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

            </v-col>
            </div>
            <Nodata v-else></Nodata>
          </v-tab-item>

        </v-tabs-items>


              <v-dialog v-model="dialogModal" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <img v-bind="attrs" v-on="on" style="position: fixed; right: 2%; bottom: 6%; width: 50px; z-index: 999;" src="@/assets/add-square.svg" alt="">
                </template>
                <v-card class="pa-5">
                  <div class="d-flex">
                    <div><p class="mt-3 ml-3 font-weight-bold">Formani to’ldiring</p></div>
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" icon @click="dialogModal=false"><img
                      src="@/assets/xExit.svg" alt="">
                    </v-btn>
                  </div>

                  <v-col cols="12">

                    <div>
                      <p class="font-weight-medium">Sotib olingan sanani tanlang</p>
                      <v-menu ref="menuCreateSaleDate" v-model="menuCreateSaleDate"
                              :close-on-content-click="false"
                              :return-value.sync="addSalesFields.created_at" transition="scale-transition"
                              offset-y left
                              min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="addSalesFields.created_at" class="mr-4" outlined
                                        label="Sanani tanlang" prepend-icon="mdi-calendar" dense readonly
                                        v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker :min="getMinDate" :max="getMaxDate" scrollable
                                        v-model.lazy="addSalesFields.created_at">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary"
                                  @click="menuCreateSaleDate = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menuCreateSaleDate.save(addSalesFields.created_at)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <div>
                      <p class="font-weight-medium">Ismni kiriting</p>
                      <v-autocomplete v-model="idCustomerName"
                                      :items="customersItems"
                                      :rules="[  v => !!v || 'Qayerdan olinganini kiriting']"
                                      placeholder="Misol: Mega sement zavodi" outlined
                                      item-text="name" item-value="name">
                      </v-autocomplete>
                    </div>

                    <p class="font-weight-medium">Mahsulot turini tanlang</p>
                    <v-list-item-group class=" justify-space-between d-flex">

                      <div class="inputBorder d-flex justify-space-between"
                            style="width: 100%">
                        <div class="d-flex pa-2 mt-1">
                          <p class="text--secondary ml-1 mt-2">Rassipnoy</p>
                        </div>
                        <div>
                          <v-radio-group v-model="addSalesFields.with_tin">
                            <v-radio @click="function(){hasTin = false; addSalesFields.tin_id = ''}"
                                      value="0"></v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                      <div class="d-flex ml-2 justify-space-between"
                            style="width: 100%">
                        <div class="inputBorder d-flex justify-space-between"
                              style="width: 100%">
                          <div class="d-flex pa-2 mt-1">
                            <p class="text--secondary ml-1 mt-2">
                              Qopli
                            </p>
                          </div>
                          <div>
                            <v-radio-group v-model="addSalesFields.with_tin">
                              <v-radio @click="hasTin = true" value="1"></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                    </v-list-item-group>

                    <br>
                    <v-list-item-group v-if="hasTin" class="justify-space-between d-flex">

                      <div class="inputBorder d-flex justify-space-between"
                            style="width: 100%">
                        <div v-show="hasTin" class="d-flex pa-2 mt-1">
                          <p class="text--secondary ml-1 mt-2">Oddiy</p>
                        </div>
                        <div>
                          <v-radio-group v-model="addSalesFields.tin_id" v-show="hasTin">
                            <v-radio value="3"></v-radio>
                          </v-radio-group>
                        </div>
                      </div>

                      <div class="d-flex ml-2 justify-space-between"
                            style="width: 100%">
                        <div v-show="hasTin" class="inputBorder d-flex justify-space-between"
                              style="width: 100%">
                          <div class="d-flex pa-2 mt-1">
                            <p class="text--secondary ml-1 mt-2">
                              Konvert
                            </p>
                          </div>
                          <div>
                            <v-radio-group v-model="addSalesFields.tin_id">
                              <v-radio value="2"></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                    </v-list-item-group>

                    <br>
                    <v-text-field v-show="hasTin" v-model="addSalesFields.quantity"
                                  onkeypress="return /[0-9]/i.test(event.key)" label="Qop soni"
                                  :rules="[  v => !!v || 'Qop sonini kiriting']"
                                  placeholder="Qop soni" outlined>
                    </v-text-field>

                    <br>
                    <p class="font-weight-medium">Massani kiriting</p>
                    <v-text-field v-model="addSalesFields.amount" onkeypress="return /[0-9]/i.test(event.key)"
                                  label="Massani kiriting"
                                  :rules="[  v => !!v || 'Qop sonini kiriting']"
                                  placeholder="Misol: 12 tonna" outlined>
                    </v-text-field>

                    <br>
                    <p class="font-weight-medium">Narxni kiriting</p>
                    <v-text-field v-model="addSalesFields.price" onkeypress="return /[0-9]/i.test(event.key)"
                                  label="Narxni kiriting"
                                  :rules="[  v => !!v || 'Qop sonini kiriting']"
                                  placeholder="Misol: 490 so'm" outlined>
                    </v-text-field>

                    <br>
                    <p class="font-weight-medium">Umumiy summa</p>
                    <v-text-field v-model="saleTotalPrice" onkeypress="return /[0-9]/i.test(event.key)"
                                  label="Umumiy summa"
                                  :rules="[  v => !!v || 'Qop sonini kiriting']"
                                  placeholder="Misol: 187,400,000 so'm" outlined>
                    </v-text-field>

                    <br>
                    <p class="font-weight-medium">Berilgan summa</p>
                    <v-text-field v-model="addSalesFields.paid" onkeypress="return /[0-9]/i.test(event.key)"
                                  label="Berilgan summa"
                                  :rules="[  v => !!v || 'Qop sonini kiriting']"
                                  placeholder="Misol: 120,000,000 so'm" outlined>
                    </v-text-field>

                    <div>
                      <p class="font-weight-medium">Qarz beriladigan sana</p>
                      <v-menu ref="menuCreateIncomeTin" v-model="menuCreateSale"
                              :close-on-content-click="false" left
                              :return-value.sync="addSalesFields.payment_deadline" transition="scale-transition"
                              offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="addSalesFields.payment_deadline" class="mr-4" outlined
                                        label="Sanani tanlang" prepend-icon="mdi-calendar" readonly
                                        v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker :min="getMinDate" :max="getMaxDate" scrollable
                                        v-model.lazy="addSalesFields.payment_deadline">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menuCreateSale = false">
                            Cancel
                          </v-btn>
                          <v-btn text color="primary"
                                  @click="$refs.menuCreateIncomeTin.save(addSalesFields.payment_deadline)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>

                  </v-col>

                  <v-card-actions>
                    <div class="d-flex justify-space-around align-center w-100">
                      <v-btn
                        color="error"
                        class="w-35"
                        style=""
                        text
                        @click="dialogModal=false">
                        Bekor qilish
                      </v-btn>

                      <v-btn
                        color="primary"
                        class="w-35"
                        large
                        elevation="0"
                        @click="addSaleData"
                        style="background: #507BE8;">
                        Davom etish
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-dialog>


      </div>
    </div>



  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {TYPE} from "vue-toastification";
import navigation from '../../components/NavigationDrawerSeller'
import Nodata from "../../components/base/NoDate";

export default {
  name: "Enter",
  data() {
    return {
      deleteModal: false,
      loadingContent: false,
      menuCreateSaleDate: false,
      reminderOpen: false,
      fieldsAdd: {},
      tabs: null,
      date: [],
      dialog: '',
      itemsPerPage: 10,
      page: 1,
      pageCount: 2,
      tab: 'first',
      ///modal
      menu: '',
      selected: [],
      isUpdating: false,
      dialogToCreate: '',
      hasCardNumber: '',
      search: '',
      productId: '',
      valid: false,
      cardNumber: [],
      customerId: '',
      headers: [{
        text: 'name',
        align: 'left',
        value: 'name'
      },
        {
          text: 'card_number',
          value: 'card_number'
        },],
      desserts: [],
      radioGroup: 1,
      e1: 1,
      stateSellorDebts: '',
      cementSalesPagination: {
        current: 1,
        total: 0
      },
      cementSales: [],
      simpleTinSales: [],
      convertTinSales: [],
      simpleTinSalesPagination: {
        current: 1,
        total: 0
      },
      convertTinSalesPagination: {
        current: 1,
        total: 0
      },
      productType: 2,
      cementUpdatedModal: false,
      simpleUpdatedModal: false,
      convertUpdatedModal: false,
      updateCementModalId: '',
      updateSimpleTinModalId: '',
      updateConvertTinModalId: '',
      cementModalFields: {
        amount: '',
        created_at: '',
        price: '',
        total_price: '',
        quantity: '',
        product_id: '',
        with_tin: '',
        tin_id: '',
        paid: ''
      },
      simpleTinModalFields: {
        amount: '',
        created_at: '',
        price: '',
        total_price: '',
        quantity: '',
        product_id: '',
        with_tin: '',
        tin_id: '',
        paid: ''
      },
      convertTinModalFields: {
        amount: '',
        created_at: '',
        price: '',
        total_price: '',
        quantity: '',
        product_id: '',
        with_tin: '',
        tin_id: '',
        paid: ''
      },
      customers: {
        name: '',
        phone_number: '',
        card_number: ''
      },
      addSales: false,
      customersItems: [],
      idCustomerName: '',
      addSalesFields: {
        customer_id: '',
        product_id: '',
        paid: '',
        with_tin: '',
        tin_id: '',
        amount: '',
        quantity: '',
        price: '',
        total_price: '',
        payment_deadline: '',
        product_type: '',
        created_at: '',
        cvartal: ''
      },
      hasCardOpen: false,
      hasCard: '',
      hasTin: false,
      hasTinValue: '',
      menuCreateSale: false,
      dialogModal: false,
      updateCementDate: false,
      cementSaleDeleteId: '',
      simpleTinSaleDeleteId: '',
      convertTinSaleDeleteId: '',
      dailySale: [],
      dailySalePagination: {
        current: 1,
        total: 0
      },
      dataReminder: {},
    }
  },

  mounted() {
    this.getCementSales();
    this.getTinSales();
    this.getConvertTinSales();
    this.dailySales();
    this.openReminder();

    let cvartal = localStorage.getItem("cvartal");

    this.$axios.get("/get-all-customers?cvartal=" + cvartal).then(response => {
      this.customersItems = response.data;
    });
  },

  methods: {

    dailySales() {
      let searchUrl;
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.search) {
        searchUrl = '&search=' + this.search;
      } else {
        searchUrl = '';
      }
      if (this.date.length) {
        dateUrl = '&date=' + this.date;
      } else {
        dateUrl = '';
      }

      this.$axios.get('/daily-sale?cvartal=' + cvartal + searchUrl + dateUrl + '&page=' + this.dailySalePagination.current).then(response => {
        this.dailySale = response.data.data;
        this.dailySalePagination.current = response.data.current_page;
        this.dailySalePagination.total = response.data.last_page;
      }).catch(error => {
        console.log(error)
      });
    },

    addSaleData() {
      const customerNameId = this.customersItems.find(el => el.name === this.idCustomerName);
      this.addSalesFields.customer_id = customerNameId.id;
      if (this.addSalesFields.with_tin == 0) {
        this.addSalesFields.product_type = "cement";
      }


      if (this.addSalesFields.with_tin == 1 && this.addSalesFields.tin_id == 3) {
        this.addSalesFields.product_type = "simple_tin_cement";
      }

      if (this.addSalesFields.with_tin == 1 && this.addSalesFields.tin_id == 2) {
        this.addSalesFields.product_type = "convert_tin_cement";
      }

      this.addSalesFields.total_price = this.saleTotalPrice;

      this.addSalesFields.cvartal = localStorage.getItem("cvartal");

      this.$axios
        .post("/sales", this.addSalesFields)
        .then(response => {

          if (response.data.success === true) {
            //Update table
            this.getTinSales();
            this.getConvertTinSales();
            this.getCementSales();
            //Close modal
            this.dialogModal = false;
            //Success notification message
            this.$toast.success(response.data.message);
            this.addSalesFields = {};
            this.hasTin = false;
            this.addSalesFields.with_tin = '';
            this.addSalesFields.tin_id = '';
            this.convertTinModalFields.with_tin = '';
            this.convertTinModalFields.tin_id = '';
            this.simpleTinModalFields.with_tin = '';
            this.simpleTinModalFields.tin_id = '';
            this.cementModalFields.with_tin = '';
            this.cementModalFields.tin_id = '';
          } else {
            this.$toast(response.data.message, {
              type: TYPE.ERROR  // or "success", "error", "default", "info" and "warning"
            });
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            //Error notification message
            this.$toast.error("Xatolik, ma'lumotlarni to'liq to'ldiring!");
          }
        });
    },

    getSumOfPayments(arr) {
      let sum = 0;
      arr.forEach((el) => {
        sum += el.payment;
      })
      return sum;
    },

    setProductType(status) {
      this.productType = status;
      this.getCementSales();
      this.getTinSales();
      this.getConvertTinSales();
    },

    onCementSalesPageChange() {
      this.getCementSales();
    },

    onTinSalesPageChange() {
      this.getTinSales();
    },

    onConvertTinSalesPageChange() {
      this.getConvertTinSales();
    },

    onDailySalesPageChange() {
      this.dailySale();
    },

    getCementSales() {
      this.loadingContent = true;
      let searchUrl;
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.search) {
        searchUrl = '&search=' + this.search;
      } else {
        searchUrl = '';
      }
      if (this.date.length) {
        dateUrl = '&date=' + this.date;
      } else {
        dateUrl = '';
      }

      this.$axios.get('/sales-by-status?status=' + this.productType + '&cvartal=' + cvartal + searchUrl + dateUrl + '&page=' + this.cementSalesPagination.current).then(response => {
        this.cementSales = response.data.cementSales.data;
        this.cementSalesPagination.current = response.data.cementSales.current_page;
        this.cementSalesPagination.total = response.data.cementSales.last_page;
      }).catch(error => {
        console.log(error)
      });
    },

    getTinSales() {
      // incomes
      let searchUrl;
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.search) {
        searchUrl = '&search=' + this.search;
      } else {
        searchUrl = '';
      }
      if (this.date.length) {
        dateUrl = '&date=' + this.date;
      } else {
        dateUrl = '';
      }

      this.$axios.get('/sales-by-status?status=' + this.productType + '&cvartal=' + cvartal + searchUrl + dateUrl + '&page=' + this.simpleTinSalesPagination.current).then(response => {
        this.simpleTinSales = response.data.tinSimples.data;
        this.simpleTinSalesPagination.current = response.data.tinSimples.current_page;
        this.simpleTinSalesPagination.total = response.data.tinSimples.last_page;
      }).catch(error => {
        console.log(error)
      });
    },

    getConvertTinSales() {
      // incomes
      let searchUrl;
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.search) {
        searchUrl = '&search=' + this.search;
      } else {
        searchUrl = '';
      }
      if (this.date.length) {
        dateUrl = '&date=' + this.date;
      } else {
        dateUrl = '';
      }
      this.$axios.get('/sales-by-status?status=' + this.productType + '&cvartal=' + cvartal + searchUrl + dateUrl + '&page=' + this.convertTinSalesPagination.current).then(response => {
        this.convertTinSales = response.data.tinConvert.data;
        this.convertTinSalesPagination.current = response.data.tinConvert.current_page;
        this.convertTinSalesPagination.total = response.data.tinConvert.last_page;
      }).catch(error => {
        console.log(error)
      });
    },

    routeLinks(item) {
      this.routeIconsNav.forEach(e => {
        e.active = true
      })
      item.active = false
      this.$router.push(item.path)
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    openReminder() {
      this.reminderOpen = true;
      let cvartal = localStorage.getItem("cvartal");
      this.$axios.get("/sale-reminder?cvartal=" + cvartal).then(response => {
        this.dataReminder = response.data;
      });
    }
  },

  computed: {

    getMinDate() {
      let cvartal = localStorage.getItem("cvartal");
      return cvartal.substring(0, 10);
    },

    getMaxDate() {
      let cvartal = localStorage.getItem("cvartal");
      return cvartal.substring(11);
    },

    ...mapGetters(['routeIcons']),
    'onSearch'() {
      return this.search;
    }
    ,
    'onDate'() {
      return this.date;
    },
    saleTotalPrice() {
      return this.addSalesFields.amount * this.addSalesFields.price
    },

    drawerNav: {
        get: function() {
            return this.$store.state.common.drawerNav
        },
        set: function(newValue) {
            return this.$store.state.common.drawerNav = newValue
        }
    },

  },

  watch: {
    onSearch() {
      this.cementSalesPagination.current = 1;
      this.convertTinSalesPagination.current = 1;
      this.simpleTinSalesPagination.current = 1;
      this.getCementSales();
      this.getTinSales();
      this.getConvertTinSales();
      this.dailySales();
    }
    ,
    onDate() {
      this.cementSalesPagination.current = 1;
      this.convertTinSalesPagination.current = 1;
      this.simpleTinSalesPagination.current = 1;
      this.getCementSales();
      this.getTinSales();
      this.getConvertTinSales();
    }
  },

  components: {
    navigation, Nodata
  }
}
</script>
<style scoped>

</style>
