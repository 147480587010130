import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)


export default {
    state: {
        dateFinance: [],
        reminders: [],
        desc: {description: '', created_at: ''},
    },
    mutations: {
        SET_DATE_FINANCE(state, dateFinance) {
            state.dateFinance = dateFinance
        },
        ADD_DESCRIPTON(state, payload) {
            state.desc.description = payload.description
            state.desc.created_at = payload.created_at
        },
        SET_REMINDER(state, payload) {
            state.reminders = payload
        }
    },
    actions: {
        sendDateFinance({commit, dispatch}, payload) {
            axios.get(process.env.VUE_APP_API_BASE_URL + '/get-finance-data', {
                params: {
                    date: payload,
                    ...this.params
                }
            })
                .then(async dateOfFinance => {
                    await dispatch('loadTotalArray')
                    commit('SET_DATE_FINANCE', payload)
                    commit('setLoading', false)
                    return dateOfFinance;
                })
        },
        addReminder({commit}, payload) {
            commit('setLoading', true)
            axios.post(process.env.VUE_APP_API_BASE_URL + '/reminder', payload)
                .then(result => {
                    commit('ADD_DESCRIPTON', payload)
                    commit('setLoading', false)
                    commit('setMessage', 'Muffaqiyatli qoshildi')
                    return result;
                })
        },
        loadReminder({commit}) {
            commit('setLoading', true)
            axios.get(process.env.VUE_APP_API_BASE_URL + '/reminder')
                .then(result => result.data)
                .then(customers => {
                    commit('SET_REMINDER', customers)
                    commit('setLoading', false)
                })
            commit('clearMessage')
        },
    },
    getters: {
        dateFinance(state) {
            return state.dateFinance
        },
        reminders(state) {
            return state.reminders
        }
    }
}
