import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)
export default {
    state: {
        incomes: [],
        sellers: [],
        fieldsIncomes: {amount: '', total_price: '', price: ''},
        fieldsIncomesAdd: {
            seller_id: '',
            product_id: '',
            tin_id: '',
            amount: '',
            product_type: '',
            train_number: '',
            price: '',
            total_price: '',
            paid: '',
            created_at: '',
        },
        salerAdd: {
            name: '',
            phone_number: '',
            company: '',
        },
        oldAmount: '',
    },
    mutations: {
        SET_INCOMES(state, incomes) {
            state.incomes = incomes
        },
        SET_SELLERS(state, sellers) {
            state.sellers = sellers
        },
        ADD_INCOME(state, payload) {
            state.fieldsIncomesAdd.train_number = payload.train_number
            state.fieldsIncomesAdd.customer_id = payload.customer_id
            state.fieldsIncomesAdd.product_type = payload.product_type
            state.fieldsIncomesAdd.product_id = payload.product_id
            state.fieldsIncomesAdd.seller_id = payload.seller_id
            state.fieldsIncomesAdd.tin_id = payload.tin_id
            state.fieldsIncomesAdd.amount = payload.amount
            state.fieldsIncomesAdd.price = payload.price
            state.fieldsIncomesAdd.total_price = payload.total_price
            state.fieldsIncomesAdd.paid = payload.paid
            state.fieldsIncomesAdd.created_at = payload.created_at
        },
        CLEAN_INCOMES(state) {
            state.fieldsIncomesAdd.customer_id = ''
            state.fieldsIncomesAdd.product_type = ''
            state.fieldsIncomesAdd.product_id = ''
            state.fieldsIncomesAdd.with_tin = ''
            state.fieldsIncomesAdd.tin_id = ''
            state.fieldsIncomesAdd.amount = ''
            state.fieldsIncomesAdd.price = ''
            state.fieldsIncomesAdd.total_price = ''
            state.fieldsIncomesAdd.paid = ''
            state.fieldsIncomesAdd.created_at = ''
        },
        ADD_SALER(state, payload) {
            state.fieldsIncomesAdd.name = payload.name
            state.fieldsIncomesAdd.phone_number = payload.phone_number
            state.fieldsIncomesAdd.company = payload.company
        },
        EDIT_INCOMES(state, payload) {
            const FindElement = state.incomes.findIndex(el => el.id == payload.id)
            if (FindElement != -1) {
                state.incomes[FindElement].amount = payload.amount
                state.incomes[FindElement].total_price = payload.total_price
                state.incomes[FindElement].price = payload.price
                state.incomes[FindElement].paid = payload.paid
            }
        },
        GET_INCOME_ID(state, payload) {
            state.fieldsIncomes = payload
        }
    },
    actions: {
        async updateIncomes({commit, dispatch}, payload) {
            commit('setLoading', true)
            await axios.put(process.env.VUE_APP_API_BASE_URL + '/incomes/' + payload.id, payload)
                .then(response => {
                    commit('EDIT_INCOMES', response.data)
                    dispatch('loadIncomesForCommit')
                    commit('clearMessage')
                    commit('setLoading', false)
                    commit('setMessage', 'Ozgartirildi')
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        console.log(error)
                        commit('setMessage', error)
                    }
                });
        },
        getIncomesId({commit}, payload) {
            axios.get(process.env.VUE_APP_API_BASE_URL + "/incomes/" + payload.id).then(response => {
                commit('GET_INCOME_ID', response.data)
            })
        },
        addIncome({commit, dispatch}, payload) {
            commit('setLoading', true)
            axios.post(process.env.VUE_APP_API_BASE_URL + '/incomes', payload)
                .then(result => {
                    commit('ADD_INCOME', payload)
                    commit('setLoading', false)
                    commit('CLEAN_SALES')
                    dispatch('loadSalesForCommit')
                    commit('setMessage', 'Mahsulot qoshildi')
                    return result
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        console.log(error)
                        commit('setMessage', error)
                    }
                });

        },
        loadIncomes({commit}) {
            commit('setLoading', true)
            axios.get(process.env.VUE_APP_API_BASE_URL + '/incomes')
                .then(result => result.data)
                .then(incomes => {
                    commit('SET_INCOMES', incomes)
                    commit('setLoading', false)
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        console.log(error)
                        commit('setMessage', error)
                    }
                });
        },

        addSaler({commit, dispatch}, payload) {
            axios.post(process.env.VUE_APP_API_BASE_URL + '/sellers', payload)
                .then(result => {
                    commit('ADD_SALER', payload)
                    dispatch('loadSellers')
                    commit('setMessage', 'Sotuvchi qoshildi')
                    return result
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        console.log(error)
                        commit('setMessage', error)
                    }
                });
        },
        loadSellers({commit}) {
            commit('setLoading', true)
            axios.get(process.env.VUE_APP_API_BASE_URL + '/sellers')
                .then(result => result.data)
                .then(sellers => {
                    commit('SET_SELLERS', sellers)
                    commit('setLoading', false)
                    commit('clearMessage')
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        console.log(error)
                        commit('setMessage', error)
                    }
                });

        },
        loadIncomesForCommit({commit}) {
            commit('setLoading', true)
            axios.get(process.env.VUE_APP_API_BASE_URL + '/incomes')
                .then(result => result.data)
                .then(incomes => {
                    commit('SET_INCOMES', incomes)
                    commit('setLoading', false)
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        console.log(error)
                        commit('setMessage', error)
                    }
                });
            commit('clearMessage')
        },
    },
    getters: {
        incomes(state) {
            return state.incomes
        },
        fieldsIncomes(state) {
            return state.fieldsIncomes
        },
        fieldsIncomesAdd(state) {
            return state.fieldsIncomesAdd
        },
        salerAdd(state) {
            return state.salerAdd
        },
        sellers(state) {
            return state.sellers
        },
        incomesPaidTotal: state => state.incomes.reduce((acc, val) => acc + val.paid, 0),
        totalIncome: state => state.fieldsIncomesAdd.amount * state.fieldsIncomesAdd.price,
        deptIncome: state => state.fieldsIncomesAdd.total_price -= state.fieldsIncomesAdd.paid
    }
}
