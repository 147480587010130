import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)
export default {
    state: {
        categories: [],
        totalArray:[],
        expenses:[],
        categoriesAdd:{category_id:'',created_at:'',money:'',description:''}
    },
    mutations: {
        SET_CATEGORY(state, categories) {
            state.categories = categories
        },
        SET_TOTAL(state,totalArray){
            state.totalArray=totalArray
        },
        SET_EXPENSES(state,expenses){
          state.expenses=expenses
        },
        ADD_CATEGORY(state,payload){
            state.categoriesAdd.category_id=payload.category_id
            state.categoriesAdd.created_at=payload.created_at
            state.categoriesAdd.sum=payload.money
            state.categoriesAdd.description=payload.description
        }
    },
    actions: {
        loadCategory({commit}) {
            commit('setLoading', true)
            axios.get(process.env.VUE_APP_API_BASE_URL + '/categories')
                .then(result => result.data)
                .then(categories => {
                    commit('SET_CATEGORY', categories)
                    commit('setLoading', false)
                })
            commit('clearMessage')
        },
        loadExpenses({commit}){
          commit('setLoading',true)
          axios.get(process.env.VUE_APP_API_BASE_URL+ '/expenses')
              .then(result => result.data)
              .then(expenses => {
                  commit('SET_EXPENSES', expenses)
                  commit('setLoading', false)
              })
            commit('clearMessage')
        },
        loadTotalArray({commit}){
            commit('setLoading',true)
            axios.get(process.env.VUE_APP_API_BASE_URL+ '/get-finance-data')
                .then(result => result.data)
                .then(totalArray => {

                    commit('SET_TOTAL', totalArray)
                    commit('setLoading', false)
                })
            commit('clearMessage')
        },
      async  addCategory({commit},payload){
            commit('setLoading',true)
       await     axios.post(process.env.VUE_APP_API_BASE_URL+ '/expenses',payload)
                .then(result=>result.data)
                .then(categories=>{
                    commit('ADD_CATEGORY',payload)
                    commit('setLoading',false)
                    commit('setMessage','Kategoriya qoshildi')
                    return categories
                })
        }
    },
    getters: {
        categories(state){
            return state.categories
        },
        categoriesAdd(state){
            return state.categoriesAdd
        },
        expenses(state){
            return state.expenses
        },
        totalArray(state){
            return state.totalArray
        },
    }
}
