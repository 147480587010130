<template>
    <div>
        <template v-if="message">
        <v-app app>
        <div class="text-center">
            <v-snackbar
                :timeout="2000"
                @input="closeMessage"
                :value="true"
                right
                color="success"
            >
                {{message}}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        color=""
                        text
                        v-bind="attrs"
                        @click="closeMessage"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
        </v-app>
        </template>
    </div>
</template>

<script>
export default {
    name: "Message",
    data(){
        return {
        }
    },
    methods:{
        closeMessage(){
        this.$store.dispatch('clearMessage')
        }
    },
    computed:{
        message(){
            return this.$store.getters.message
        }
    }
}
</script>

<style scoped>

</style>
