<template>
  <div>
      <v-navigation-drawer app width="100" class="bgNone">
        <div class="text-center  logoDiv">
          <div>
            <navigation></navigation>
          </div>
        </div>
      </v-navigation-drawer>
      <v-app-bar app elevation="0" height="100" class="" color="white">
          <div class="pa-5 d-flex justify-space-between align-center w-100 flex-wrap">

            <div class="d-flex align-center">
              <v-app-bar-nav-icon @click="drawerNav = !drawerNav" 
                class="hidden-md-and-up"></v-app-bar-nav-icon>

              <img class="ma-2" src="@/assets/navabarIcons/debts.svg" alt="">

              <div class="d-flex align-start flex-column justify-center ml-2">
                <h2>Qarzdorliklar tarixi</h2>
                <span>Qarzlar va nasiyalar tarixi</span>
              </div>
            </div>

            <v-spacer></v-spacer>
            <div class="d-flex">
              <v-text-field prepend-inner-icon="mdi-magnify" outlined
                dense class="mt-1" v-model="search" hide-details
                label="Qidiruv..."></v-text-field>
            </div>
          </div>
      </v-app-bar>

      <div class="content__main">
          <v-tabs v-model="tab" class="bgNone" background-color="transparent">
            <v-tabs-slider></v-tabs-slider>
            <div class="d-flex align-center justify-space-between w-100 my-3">
              <div class="d-flex">
                <v-tab href="#tab-1" @click="getDeptors()" class="py-4" style="color: #91A3B8; font-size: 15px;">
                  QARZDORLAR TARIXI
                </v-tab>
                <v-tab href="#tab-2" @click="getMyDepts()" class="py-4" style="color: #91A3B8; font-size: 15px;">
                  QARZLARIM TARIXI
                </v-tab>
              </div>
              <div class="d-flex mt-1">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      class="mr-4"
                      outlined
                      label="Sanani tanlang"
                      prepend-icon="mdi-calendar"
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker scrollable :min="getMinDate" :max="getMaxDate"
                                  v-model.lazy="date"
                                  range>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="date = []"
                    >
                      O'chirish
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
          </v-tabs>
          <v-divider></v-divider>

          <v-tabs-items v-model="tab" style="background: #FCFDFF!important;">

            <v-tab-item :value="'tab-1'" style="right: 10px">

              <div v-if="!(Object.keys(saleDeptors).length === 0)">
                <v-col md="12" sm="12" style="background: #FCFDFF!important;">
                  <v-card elevation="0" style="background: #FCFDFF!important;">
                    <v-dialog v-model="dialog" max-width="700">
                      <template v-slot:activator="{ on, attrs }">
                          
                        <v-data-table
                          :items="saleDeptors"
                          hide-default-header
                          hide-default-footer
                        >
                          <template v-slot:header>
                            <v-list-item class="d-flex justify-space-between mt-2 w-100">
                                <v-list-item-title class="text--secondary">Ism</v-list-item-title>
                                <v-list-item-title class="text--secondary">Summa</v-list-item-title>
                                <v-list-item-title class="text--secondary">Sana</v-list-item-title>
                                <v-list-item-title class="text--secondary">Muddat</v-list-item-title>
                                <v-list-item-title class="text--secondary">Action</v-list-item-title>
                            </v-list-item>
                          </template>

                          <template v-slot:item="{ item, index }">

                                <v-list-item-group>
                                  <v-list-item
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="getIdDebts(item.id)">
                                      <v-list-item-icon
                                        :class="{'circleAvatar':index % 2 ===1,'circleAvatar1':index % 2 ===0,'circleAvatar2':index % 3 ===1,}">
                                        <span> {{ item.customer.name[0] }} </span>
                                      </v-list-item-icon>
                                      <v-list-item-title
                                        class="font-weight-medium"> {{ item.customer.name }}
                                      </v-list-item-title>
                                      <v-list-item-title class="text--secondary">
                                      <span class="font-weight-medium"> {{ formatPrice(getSumOfPayments(item.payments)) }}</span> so’m
                                      </v-list-item-title>
                                      <v-list-item-title class="font-weight-medium text--secondary">
                                        {{ item.payment_deadline | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY")}}
                                      </v-list-item-title>
                                      <v-list-item-title class="text--secondary">
                                        <span class="font-weight-medium"> {{
                                            fromNow(item.payment_deadline)
                                          }} </span>
                                      </v-list-item-title>
                                      <v-list-item-title
                                        style="color: #507BE8"
                                        class=" d-flex"
                                        @click="openExpences(item)">
                                        <img class="mr-2" src="@/assets/receipt-text.svg" alt="">
                                        To'lovlar tarixi
                                      </v-list-item-title>
                                  </v-list-item>
                                </v-list-item-group>
                          
                          </template>
                        </v-data-table>
                      </template>


                      <v-card class="pa-1">
                        <!-- <v-list-item class="mt-2">
                          <div class="d-flex"><img src="@/assets/IconAvatar.svg" alt="">
                            <p class="mt-3 ml-3">Sodiqmirzo Sattorov</p></div>
                          <v-spacer></v-spacer>
                          <v-btn elevation="0" icon @click="dialog=false"><img
                            src="@/assets/xExit.svg" alt=""></v-btn>
                        </v-list-item> -->

                        <v-container>
                          <v-row wrap class="pa-2">
                            <v-col cols="12">
                              <v-list-item-group class=" justify-space-between">
                                <div class="mt-2 d-flex">
                                  <v-spacer></v-spacer>
                                  <v-btn elevation="0" icon @click="dialog=false"><img
                                    src="@/assets/xExit.svg" alt=""></v-btn>
                                </div>
                                <div class="mt-5 mb-5">
                                  <v-divider class="justify-center"></v-divider>
                                </div>

                                <div v-if="sale.payments">
                                  <div v-for="item of sale.payments" :key="item.id">

                                    <v-row class="mt-2">
                                      <v-col class="font-weight-bold text--black">
                                        {{ item.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                                      </v-col>
                                      <v-col class="green--text">{{ formatPrice(item.payment) }} <span
                                        class="text--secondary">so’m</span></v-col>
                                    </v-row>
                                  </div>
                                </div>

                                <div v-if="sale.payments==0">
                                  <p class="font-weight-bold">Mavjud emas!</p>
                                </div>

                                <div class="mt-5 mb-5">
                                  <v-divider class=" justify-center"></v-divider>
                                </div>

                              </v-list-item-group>
                              <div class="mt-15"></div>

                              <v-list-item-group class="justify-space-between">
                                <div class="underExpenses pa-3">

                                  <v-row>
                                    <v-col class="font-weight-medium text--black">Umumiy
                                      savdo: <span class="text--secondary">{{
                                          formatPrice(sale.total_price)
                                        }} so’m</span>
                                    </v-col>
                                    <v-col class="font-weight-medium text--black">Jami to'lagan summasi:
                                      <span class="text--secondary" v-if="!(Object.keys(sale).length === 0)">
                                    {{ formatPrice(getSumOfPayments(sale.payments)) }}
                                  </span>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col class="font-weight-medium text--black">
                                      Qolgan qarzi:
                                      <span class="text--secondary" v-if="!(Object.keys(sale).length === 0)">
                                    {{ formatPrice(sale.total_price - getSumOfPayments(sale.payments)) }}
                                  </span>
                                    </v-col>
                                  </v-row>

                                </div>
                              </v-list-item-group>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-dialog>

                    <!--  Confirm delete modal  -->
                    <v-dialog v-model="deleteModal" persistent max-width="450">
                      <v-card class="pa-4">
                        <div class="d-flex">
                          <div><p class="mt-3 ml-3 font-weight-bold">Sotuvni o'chirish</p></div>
                          <v-spacer></v-spacer>
                          <v-btn elevation="0" icon @click="deleteModal=false"><img
                            src="@/assets/xExit.svg" alt="">
                          </v-btn>
                        </div>
                        <v-card-text class="text-center font-weight-bold" style="font-size: 18px;">
                          Quyidagi kiritmani o'chirgandan so'ng uni qayta tiklay olmaysiz! <br>
                          <img style="width: 50px; margin-top: 20px;" src="@/assets/trash.svg" alt="trash">
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" outlined text @click="deleteSaleConvertTin('only')">
                            Sotuvlardan o'chirish
                          </v-btn>
                          <v-btn color="red" outlined text @click="deleteSaleConvertTin('fully')">
                            Skladdan o'chirish
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                  </v-card>

                  <div class="mx-24">
                    <v-pagination
                      v-model="deptorsPagination.current"
                      :length="deptorsPagination.total"
                      :total-visible="7"
                      @input="onDebtorsPageChange"
                      color="#507BE8"
                      class="my-4">  
                    </v-pagination>
                  </div>

                </v-col>

                <v-row justify="center">
                  <v-dialog v-model="updatePayment" max-width="400" style="z-index: 99999">
                    <v-card class="pa-1">
                      <v-spacer></v-spacer>
                      <p class="mt-3 ml-3 font-weight-medium">Summani kiriting</p>
                      <v-list-item-group class="align-center justify-space-between pa-5">
                        <v-text-field style="box-sizing: border-box;border-radius: 18px;"
                                      v-model="updatePaymentFields.payment"
                                      outlined placeholder="0 so'm">
                        </v-text-field>
                      </v-list-item-group>
                      <div class="text-center">
                        <v-btn
                          color="error"
                          class="mr-4"
                          style="border-radius: 14px;width: 150px; height: 50px"
                          text
                          @click="updatePayment=false"
                        >
                          Bekor qilish
                        </v-btn>

                        <v-btn
                          color="primary"
                          class="mr-4 "
                          large
                          elevation="0"
                          style="background: #507BE8;border-radius: 14px;width: 150px; height: 50px"
                          @click="updatePayments"
                        >
                          Yangilash
                        </v-btn>
                      </div>
                    </v-card>
                  </v-dialog>
                </v-row>

                <v-row justify="center">
                  <v-dialog v-model="payDeptModal" max-width="400"
                            style="z-index: 99999">
                    <v-card class="pa-1">
                      <v-spacer></v-spacer>
                      <p class="mt-3 ml-3 font-weight-medium">Summani kiriting</p>
                      <v-list-item-group class="align-center justify-space-between pa-5">
                        <v-text-field style="box-sizing: border-box;border-radius: 18px;"
                                      v-model="payDeptFields.payment"
                                      outlined placeholder="0 so'm">
                        </v-text-field>
                        <v-menu v-model="payDeptPicker" :close-on-content-click="false"
                                :nudge-right="40" transition="scale-transition" left
                                offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="payDeptFields.created_at" label="Sanani tanlang"
                                          prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                          v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="payDeptFields.created_at" :min="getMinDate" :max="getMaxDate" scrollable
                                         @input="payDeptPicker = false"></v-date-picker>
                        </v-menu>
                      </v-list-item-group>
                      <div class="text-center">
                        <v-btn color="error" class="mr-4"
                               style="border-radius: 14px;width: 150px; height: 50px"
                               text @click="payDeptModal=false">
                          Bekor qilish
                        </v-btn>

                        <v-btn
                          color="primary"
                          class="mr-4 "
                          large
                          elevation="0"
                          style="background: #507BE8;border-radius: 14px;width: 150px; height: 50px"
                          @click="payDept">
                          To’lash
                        </v-btn>
                      </div>
                    </v-card>
                  </v-dialog>
                </v-row>

              </div>
              <Nodata v-else></Nodata>
            </v-tab-item>

            <v-tab-item :value="'tab-2'" style="right: 10px">
              <div v-if="!(Object.keys(incomeDeptors).length === 0)">
                <v-col md="12" sm="12" class="" style="background: #FCFDFF!important;">
                  <v-card elevation="0" class="" style="background: #FCFDFF!important;">
                    <v-dialog v-model="incomeTable" max-width="700">
                      <template v-slot:activator="{ on, attrs }">

                      <v-data-table
                        :items="incomeDeptors"
                        hide-default-header
                        hide-default-footer
                      >
                        <template v-slot:header>
                          <v-list-item class="d-flex justify-space-between mt-2 w-100">
                              <v-list-item-title class="text--secondary">Ism</v-list-item-title>
                              <v-list-item-title class="text--secondary">Summa</v-list-item-title>
                              <v-list-item-title class="text--secondary">Sana</v-list-item-title>
                              <v-list-item-title class="text--secondary">Muddat</v-list-item-title>
                              <v-list-item-title class="text--secondary">Action</v-list-item-title>
                          </v-list-item>
                        </template>

                        <template v-slot:item="{ item, index }">

                              <v-list-item-group>
                                <v-list-item
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="getIdDebtsIncomes(item.id)">
                                    <v-list-item-icon
                                      :class="{'circleAvatar':index % 2 ===1,'circleAvatar1':index % 2 ===0,'circleAvatar2':index % 3 ===1,}">
                                      <span> {{ item.seller.name[0] }} </span>
                                    </v-list-item-icon>
                                    <v-list-item-title class="font-weight-medium"> {{ item.seller.name }}
                                    </v-list-item-title>
                                    <v-list-item-title class="text--secondary">
                                    <span class="font-weight-medium">
                                      {{ formatPrice(getSumOfPayments(item.payments)) }}
                                    </span>
                                      so’m
                                    </v-list-item-title>
                                    <v-list-item-title class="font-weight-medium text--secondary">
                                      {{item.payment_deadline | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY")}}
                                    </v-list-item-title>
                                    <v-list-item-title class="text--secondary">
                                      <span class="font-weight-medium"> {{ fromNow(item.payment_deadline) }} </span>
                                    </v-list-item-title>
                                    <v-list-item-title
                                      style="color: #507BE8"
                                      class=" d-flex"
                                      @click="openExpences(item)">
                                      <img class="mr-2" src="@/assets/receipt-text.svg" alt="">
                                      To'lovlar tarixi
                                    </v-list-item-title>
                                </v-list-item>
                              </v-list-item-group>
                        
                        </template>
                      </v-data-table>

                      </template>
                      <v-card class="pa-1">
                        <!-- <v-list-item class="mt-2">
                          <div class="d-flex">

                            <p class="mt-3 ml-3">Sodiqmirzo Sattorov</p></div>
                          <v-spacer></v-spacer>
                          <v-btn elevation="0" icon @click="incomeTable=false"><img
                            src="@/assets/xExit.svg" alt=""></v-btn>
                        </v-list-item> -->

                        <v-container>
                          <v-row wrap class="pa-2">
                            <v-col cols="12">
                              <v-list-item-group class=" justify-space-between">
                                <div class="mt-2 d-flex">
                                  <v-spacer></v-spacer>
                                  <v-btn elevation="0" icon @click="incomeTable=false"><img
                                    src="@/assets/xExit.svg" alt=""></v-btn>
                                </div>
                                <div class="mt-5 mb-5">
                                  <v-divider class="justify-center"></v-divider>
                                </div>

                                <div v-if="income.payments">
                                  <div v-for="item of income.payments" :key="item.id">

                                    <v-row class="mt-2">
                                      <v-col class="font-weight-bold text--black">
                                        {{ item.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                                      </v-col>
                                      <v-col class="green--text">{{ formatPrice(item.payment) }} <span
                                        class="text--secondary">so’m</span></v-col>
                                    </v-row>
                                  </div>
                                </div>

                                <div v-if="income.payments==0">
                                  <p class="font-weight-bold">Mavjud emas!</p>
                                </div>

                                <div class="mt-5 mb-5">
                                  <v-divider class="justify-center"></v-divider>
                                </div>

                              </v-list-item-group>
                              <div class="mt-15"></div>

                              <v-list-item-group class="justify-space-between">
                                <div class="underExpenses pa-3">
                                  <v-row>
                                    <v-col class="font-weight-medium text--black">Umumiy
                                      savdo: <span class="text--secondary">{{
                                          formatPrice(income.total_price)
                                        }} so’m</span>
                                    </v-col>
                                    <v-col class="font-weight-medium text--black">
                                      Jami to'lagan summasi: <span v-if="!(Object.keys(income).length === 0)"
                                                                    class="text--secondary">{{
                                        formatPrice(getSumOfPayments(income.payments))
                                      }} </span>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col class="font-weight-medium text--black">Qolgan
                                      qarz:<span class="text--secondary" v-if="!(Object.keys(income).length === 0)">
                                    {{ formatPrice(income.total_price - getSumOfPayments(income.payments)) }}
                                  </span>
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-list-item-group>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-dialog>
                  </v-card>
                  <div class="mx-24">
                    <v-pagination v-model="incomePagination.current" :length="incomePagination.total" :total-visible="7"
                      @input="onIncomeDebtorsPageChange"
                      color="#507BE8" class="my-4"></v-pagination>
                  </div>
                </v-col>  

                <v-row justify="center">
                  <v-dialog v-model="updatePaymentIncomes" max-width="400"
                            style="z-index: 99999">
                    <v-card class="pa-1">
                      <v-spacer></v-spacer>
                      <p class="mt-3 ml-3 font-weight-medium">Summani kiriting</p>
                      <v-list-item-group class="align-center justify-space-between pa-5">
                        <v-text-field style="box-sizing: border-box;border-radius: 18px;"
                                      v-model="updateIncomePaymentFields.payment"
                                      outlined placeholder="0 so'm">
                        </v-text-field>
                      </v-list-item-group>
                      <div class="text-center">
                        <v-btn color="error" class="mr-4"
                               style="border-radius: 14px;width: 150px; height: 50px" text
                               @click="updatePaymentIncomes=false">
                          Bekor qilish
                        </v-btn>

                        <v-btn
                          color="primary"
                          class="mr-4 "
                          large
                          elevation="0"
                          style="background: #507BE8;border-radius: 14px;width: 150px; height: 50px"
                          @click="updateIncomePayments"
                        >
                          Yangilash
                        </v-btn>
                      </div>
                    </v-card>
                  </v-dialog>
                </v-row>

                <v-row justify="center">
                  <v-dialog v-model="payDeptIncomeModal" max-width="400" style="z-index: 99999">
                    <v-card class="pa-1">
                      <v-spacer></v-spacer>
                      <p class="mt-3 ml-3 font-weight-medium">Summani kiriting</p>
                      <v-list-item-group class="align-center justify-space-between pa-5">
                        <v-text-field style="box-sizing: border-box;border-radius: 18px;"
                                      v-model="payDeptIncomeFields.payment"
                                      outlined placeholder="0 so'm">
                        </v-text-field>
                        <v-menu v-model="payIncomeDeptPicker" :close-on-content-click="false"
                                :nudge-right="40" transition="scale-transition" left
                                offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="payDeptIncomeFields.created_at" label="Sanani tanlang"
                                          prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                          v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="payDeptIncomeFields.created_at" :min="getMinDate" :max="getMaxDate" scrollable
                                         @input="payIncomeDeptPicker = false"></v-date-picker>
                        </v-menu>
                      </v-list-item-group>
                      <div class="text-center">
                        <v-btn color="error" class="mr-4"
                               style="border-radius: 14px;width: 150px; height: 50px"
                               text @click="payDeptIncomeModal=false">
                          Bekor qilish
                        </v-btn>

                        <v-btn
                          color="primary"
                          class="mr-4 "
                          large
                          elevation="0"
                          style="background: #507BE8;border-radius: 14px;width: 150px; height: 50px"
                          @click="payDeptIncome">
                          To’lash
                        </v-btn>
                      </div>
                    </v-card>
                  </v-dialog>
                </v-row>

              </div>
              <Nodata v-else></Nodata>
            </v-tab-item>

          </v-tabs-items>


      </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {TYPE} from "vue-toastification";
import moment from "moment-timezone";
import navigation from '../../components/NavigationDrawerSeller'
import Nodata from "../../components/base/NoDate";

export default {
  name: "DebtsHistory",
  data() {
    return {
      tabs: null,
      date: [],
      tab: 'first',
      dialog: false,
      payIncomeDeptPicker: false,
      incomeTable: false,
      validate: false,
      selected: [],
      menu1: '',
      valid: '',
      page: 1,
      perPage: 20,
      totalPaidGet: '',
      openWindow: false,
      payment: '',
      date1: '',
      deptChange: '',
      totalToPay: {sale_id: '', payment: ''},
      totalDeptGet: '',
      totalGet: '',
      sale_id: '',
      menu: '',
      items: [
        {text: 'Real-Time', icon: 'mdi-clock', bg: true},
        {text: 'Audience', icon: 'mdi-account', bg: false},
        {text: 'Conversions', icon: 'mdi-flag', bg: true},
        {text: 'Real-Time', icon: 'mdi-clock', bg: false},
        {text: 'Conversions', icon: 'mdi-flag', bg: true},
        {text: 'Real-Time', icon: 'mdi-clock', bg: false},
        {text: 'Real-Time', icon: 'mdi-clock', bg: false},
        {text: 'Conversions', icon: 'mdi-flag', bg: true},
        {text: 'Real-Time', icon: 'mdi-clock', bg: false},
      ],
      deptorsPagination: {
        current: 1,
        total: 0
      },
      incomePagination: {
        current: 1,
        total: 0
      },
      productType: 1,
      saleDeptors: [],
      incomeDeptors: [],
      search: '',
      idDeptor: '',
      sale: {},
      updatePayment: false,
      updatePaymentIncome: false,
      editPaymentId: '',
      editPaymentIncomeId: '',
      updatePaymentFields: {
        payment: '',
        created_at: ''
      },
      updateIncomePaymentFields: {
        payment: ''
      },
      payDeptModal: false,
      editPayDeptModal: false,
      payDeptFields: {
        payment: '',
        sale_id: '',
        created_at: ''
      },
      payDeptPicker: false,
      updatePaymentIncomes: false,
      idDeptorIncomes: '',
      income: {},
      payDeptIncomeModal: false,
      payDeptIncomeFields: {
        payment: '',
        income_id: '',
        created_at: ''
      },
      ownDept: true
    }
  },

  watch: {
    onSearch() {
      this.deptorsPagination.current = 1;
      this.incomePagination.current = 1;
      this.getDeptors();
      this.getMyDepts();
    },
    onDate() {
      this.deptorsPagination.current = 1;
      this.incomePagination.current = 1;
      this.getDeptors();
      this.getMyDepts();
    }
  },

  mounted() {
    this.$axios.get("/incomes").then(response => {
      this.customersItems = response.data;
    });
    this.getDeptors();
  },

  computed: {

    getMinDate() {
      let cvartal = localStorage.getItem("cvartal");
      return cvartal.substring(0,10);
    },

    getMaxDate() {
      let cvartal = localStorage.getItem("cvartal");
      return cvartal.substring(11);
    },

    ...mapGetters(['routeIcons', 'debtors', 'debtorsId']),

    'onSearch'() {
      return this.search;
    },
    'onDate'() {
      return this.date;
    },
    drawerNav: {
        get: function() {
            return this.$store.state.common.drawerNav
        },
        set: function(newValue) {
            return this.$store.state.common.drawerNav = newValue
        }
    },
  },

  methods: {
    getMyDepts() {
      let searchUrl;
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.search) {
        searchUrl = '&search=' + this.search;
      } else {
        searchUrl = '';
      }
      if (this.date.length) {
        dateUrl = '&date=' + this.date;
      } else {
        dateUrl = '';
      }

      this.$axios.get('/get-income-debtors?page=' + this.incomePagination.current + '&cvartal=' + cvartal + searchUrl + dateUrl).then(response => {
        this.incomeDeptors = response.data.debtorsHistory.data;
        this.incomePagination.current = response.data.debtorsHistory.current_page;
        this.incomePagination.total = response.data.debtorsHistory.last_page;
      }).catch(error => {
        console.log(error)
      });
    },

    payDept() {
      this.payDeptFields.sale_id = this.sale.id;
      this.$axios
        .post("/sale-payment", this.payDeptFields)
        .then(response => {
          if (response.data.success === true) {
            this.payDeptModal = false;
            this.getIdDebts(this.sale.id);
            this.getDeptors();
            //Success notification message
            this.$toast.success(response.data.message);
            this.payDeptFields = {};
          } else {
            this.$toast(response.data.message, {
              type: TYPE.ERROR  // or "success", "error", "default", "info" and "warning"
            });
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            //Error notification message
            this.$toast.error("Пожалуйста, попробуйте еще раз!");
          }
        });
    },

    updatePayments() {
      this.$axios
        .put("/sale-payment/" + this.editPaymentId, this.updatePaymentFields)
        .then(response => {
          if (response.data.success === true) {
            this.getIdDebts(this.idDeptor);
            this.getDeptors();
            this.updatePayment = false;
            this.$toast.success(response.data.message);
            this.updatePaymentFields = {};
            this.editPaymentId = ''
          } else {
            this.$toast(response.data.message, {
              type: TYPE.ERROR  // or "success", "error", "default", "info" and "warning"
            });
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            //Error notification message
            this.$toast.error("Iltimos, qayta urinib ko'ring!");
          }
        });
    },

    editPayment(id) {
      this.editPaymentId = id;
      this.updatePayment = true;
      this.$axios.get("/sale-payment/" + this.editPaymentId).then(response => {
        this.updatePaymentFields = response.data;
      });
    },

    editPaymentIncome(id) {
      this.editPaymentIncomeId = id;
      this.updatePaymentIncomes = true;
      this.$axios.get("/income-payment/" + this.editPaymentIncomeId).then(response => {
        this.updateIncomePaymentFields = response.data;
      });
    },

    updateIncomePayments() {
      this.$axios
        .put("/income-payment/" + this.editPaymentIncomeId, this.updateIncomePaymentFields)
        .then(response => {
          if (response.data.success === true) {
            this.getIdDebtsIncomes(this.idDeptorIncomes);
            this.updatePaymentIncomes = false;
            this.$toast.success(response.data.message);
            this.updateIncomePaymentFields = {};
            this.editPaymentIncomeId = ''
          } else {
            this.$toast(response.data.message, {
              type: TYPE.ERROR  // or "success", "error", "default", "info" and "warning"
            });
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            //Error notification message
            this.$toast.error("Iltimos, qayta urinib ko'ring!");
          }
        });
    },

    payDeptIncome() {
      this.payDeptIncomeFields.income_id = this.income.id;
      this.$axios
        .post("/income-payment", this.payDeptIncomeFields)
        .then(response => {
          if (response.data.success === true) {
            this.payDeptIncomeModal = false;
            this.getIdDebtsIncomes(this.income.id);
            this.getMyDepts();
            //Success notification message
            this.$toast.success(response.data.message);
            this.payDeptIncomeFields = {};
          } else {
            this.$toast(response.data.message, {
              type: TYPE.ERROR  // or "success", "error", "default", "info" and "warning"
            });
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            //Error notification message
            this.$toast.error("Пожалуйста, попробуйте еще раз!");
          }
        });
    },

    getIdDebts(id) {
      this.idDeptor = id;
      if (this.idDeptor) {
        this.$axios.get("/sales/" + this.idDeptor).then(response => {
          this.sale = response.data;
        });
      }
    },

    getIdDebtsIncomes(id) {
      this.idDeptorIncomes = id;
      if (this.idDeptorIncomes) {
        this.$axios.get("/incomes/" + this.idDeptorIncomes).then(response => {
          this.income = response.data
        });
      }
    },

    getDeptors() {
      let searchUrl;
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.search) {
        searchUrl = '&search=' + this.search;
      } else {
        searchUrl = '';
      }
      if (this.date.length) {
        dateUrl = '&date=' + this.date;
      } else {
        dateUrl = '';
      }

      this.$axios.get('/get-sale-debtors?page=' + this.deptorsPagination.current + '&cvartal=' + cvartal + searchUrl + dateUrl).then(response => {
        this.saleDeptors = response.data.saleDebtsHistory.data;
        this.deptorsPagination.current = response.data.saleDebtsHistory.current_page;
        this.deptorsPagination.total = response.data.saleDebtsHistory.last_page;
      }).catch(error => {
        console.log(error)
      });
    },

    getSumOfPayments(arr) {
      let sum = 0;
      arr.forEach((el) => {
        sum += el.payment;
      })
      return sum;
    },

    onDebtorsPageChange() {
      this.getDeptors();
    },

    onIncomeDebtorsPageChange() {
      this.getMyDepts();
    },

    payForIt() {
      this.id = this.deptChange.id
      this.totalToPay.sale_id = this.id
      this.totalToPay.payment = this.payment
      this.$store.dispatch('sendPayment', this.totalToPay)
      this.dialog1 = false
    },

    openWindowPay(item) {
      this.dialog1 = !this.dialog1
      this.deptChange = item
    },

    reducerTotal(item) {
      return item.sales.map(item => item.dept).reduce((a, b) => a + b, 0)
    },
    routeLinks(item) {
      this.routeIconsNav.forEach(e => {
        e.active = true
      })
      item.active = false
      this.$router.push(item.path)
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    fromNow(date) {
      return moment(date).fromNow();
    },
  },

  components: {
    navigation, Nodata
  }
}
</script>

<style scoped>

</style>
