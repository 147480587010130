import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home'
import Authentication from '../views/Authentication'
import Enter from '../views/Enter'
import Storage from "../views/Storage";
import Exit from "../views/Exit";
import Clients from "../views/Clients";
import Sellers from "../views/Sellers";
import Debts from "../views/Debts";
import DebtHistory from "../views/DebtsHistory";
import Finance from "../views/Finance";
import SalerEnter from "../views/seller/SalerEnter";
import SalerExit from "../views/seller/SalerExit";
import SalerStoratge from "../views/seller/SaleStorage";
import salerClients from "../views/seller/saleClients";
import SalerDebts from "../views/seller/SaleDebts";
import SalerSellers from "../views/seller/SellerSellers";
import SalerDebtsHistory from "../views/seller/SalerDebtsHistory";
import SalerFinance from "../views/seller/SalerFinance";

Vue.use(Router)
const router = new Router({
    routes: [

        {
            path: '/',
            component: Authentication,
            name: 'authentication',
            meta: {
                layout: 'AuthLayout'
            }
        },
        {
            path: '/dashboard',
            component: Home,
            name: 'Home',
            meta: {
                auth: true
            }
        },
        {
            path: '/finance',
            component: Finance,
            name: 'Finance',
            meta: {
                layout: 'FinanceLayout',
                auth: true
            }
        },
        {
            path: '/clients',
            component: Clients,
            name: 'Clients',
            meta: {
                // layout: 'CleanLayout',
                auth: true
            }
        },
        {
            path: '/sellers',
            component: Sellers,
            name: 'Sellers',
            meta: {
                // layout: 'CleanLayout',
                auth: true
            }
        },
        {
            path: '/exit',
            component: Exit,
            name: 'Exit',
            meta: {
                // layout: 'CleanLayout',
                auth: true
            }
        },
        {
            path: '/storage',
            component: Storage,
            name: 'Storage',
            meta: {
                // layout: 'CleanLayout',
                auth: true
            }
        },
        {
            path: '/enter',
            component: Enter,
            name: 'Enter',
            meta: {
                // layout: 'CleanLayout',
                auth: true
            }
        },
        {
            path: '/debts',
            component: Debts,
            name: 'Debts',
            meta: {
                // layout: 'CleanLayout',
                auth: true
            }
        },
        {
            path: '/debts-history',
            component: DebtHistory,
            name: 'DebtHistory',
            meta: {
                // layout: 'CleanLayout',
                auth: true
            }
        },
        {
            path: '/seller/enter',
            component: SalerEnter,
            name: 'SalerEnter',
            meta: {
                // layout: 'CleanLayout'
            }
        },
        {
            path: '/seller/sellers',
            component: SalerSellers,
            name: 'SalerSellers',
            meta: {
                // layout: 'CleanLayout'
            }
        },
        {
            path: '/seller/exit',
            component: SalerExit,
            name: 'SalerExit',
            meta: {
                // layout: 'CleanLayout'
            }
        },
        {
            path: '/seller/debts',
            component: SalerDebts,
            name: 'SalerDebts',
            meta: {
                // layout: 'CleanLayout'
            }
        },
        {
            path: '/seller/debts-history',
            component: SalerDebtsHistory,
            name: 'SalerDebtsHistory',
            meta: {
                // layout: 'CleanLayout',
                auth: true
            }
        },
        {
            path: '/seller/clients',
            component: salerClients,
            name: 'salerClients',
            meta: {
                // layout: 'CleanLayout'
            }
        },
        {
            path: '/seller/storage',
            component: SalerStoratge,
            name: 'SalerStoratge',
            meta: {
                // layout: 'CleanLayout'
            }
        },
        {
            path: '/seller/finance',
            component: SalerFinance,
            name: 'SalerFinance',
            meta: {
                // layout: 'CleanLayout'
            }
        },
    ],
    mode: 'history'
})


// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
    const requireAuth = !!(to.meta && to.meta.auth)
    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    // console.log("token", token)
    // console.log("beforeEach", to)
    if (requireAuth && token && role) {
        if (to.name === 'authentication') {
            next({name: "Home"})
            return
        }
        next()
        return
    }

    if (token && role) {
        if (to.name === "authentication") {
            next({name: "Home"})
            return
        }
    } else {
        if (to.name !== "authentication") {
            next({name: "authentication"})
            return
        }
    }
    next()
})

export default router
