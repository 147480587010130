<template>
  <div>
    <v-list>
      <img style="width: 50px;" src="../assets/magnat-logo.png" alt="">
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
        class="listOfNavigation"
      >
        <v-list-item
          v-for="(item, i) in routeIconsSeller"
          :key="i"
          class="linksOfNavigation pl-8"
          :class="[item.active ? 'giveClassActive' : '']"
          @click="routeLinks(item)"
        >
          <v-list-item-icon>
            <img :src="item.iconUnactive" alt="">
          </v-list-item-icon>
          <v-list-item-content class="d-none">
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <avatar ></avatar>
    </v-list>
  </div>
</template>

<script>
import avatar from '../components/AvatarModal'
import {mapGetters} from 'vuex'

export default {
  name: "NavigationDrawer",
  computed: {
    ...mapGetters(['routeIconsSeller']),

  }, methods: {
    routeLinks(item) {
      this.$router.push(item.path)
      this.routeIconsSeller.forEach(e => {
        e.active = false
      })
      item.active = true
    },
  },
  components: {
    avatar
  },
  data() {
    return {
      selectedItem: ''
    }
  }
}
</script>

<style>
.giveClassActive {
  background: #e3f2fd;
}
</style>
