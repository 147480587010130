import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default {
    state: {
        loading: false,
        message: null,
        errorMessage:null
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setMessage(state, payload) {
            state.message = payload
        },
        setErrorMessage(state, payload) {
            state.errorMessage = payload
        },
        clearMessage(state) {
            state.message = null
        }
    },
    actions: {
        setLoading({commit}, payload) {
            commit('setLoading', payload)
        },
        setMessage({commit}, payload) {
            commit('setMessage', payload)
        },
        setErrorMessage({commit},payload){
            commit('setErrorMessage', payload)
        },
        clearMessage({commit}) {
            commit('clearMessage')
        }
    },
    getters: {
        loading(state) {
            return state.loading
        },
        message(state) {
            return state.message
        },
        errorMessage(state){
            return state.errorMessage
        }
    }
}
