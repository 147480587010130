<template>
  <div>
      <v-app-bar app elevation="0" height="100" class="appBar" color="white">
        <div class="d-flex justify-space-between align-center flex-wrap w-100 px-5 pt-2">

          <v-app-bar-nav-icon @click="drawerNav = !drawerNav" 
            class="hidden-md-and-up mr-3"></v-app-bar-nav-icon>

          <p style="font-size: 29px;" class="font-weight-medium mb-0">
            Assalamu alaykum!
          </p>

          <p class="magnatInfo mb-0 ml-5">
            Magnat boshqaruv paneliga xush kelibsiz
          </p>

          <v-spacer class=""></v-spacer>

        </div>
    </v-app-bar>

    <div class="content__main content__home" >
      <v-row>
        <v-col cols="12"> 
          <v-row class="mx-auto align-center">

            <v-col cols="12" md="4" lg="3" sm="6">
              <v-card max-width="300" outlined style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12);
border-radius: 12px; border: none">
                <v-list-item>
                  <v-list-item-content>
                    <div class="d-flex mb-4 ">
                      <img src="../assets/sement.svg" alt="">
                      <p class="font-weight-medium textCardProduct mt-4 ml-4 text--secondary">
                        MAVJUD SEMENT
                      </p>
                    </div>
                    <div class="d-flex ml-2">
                      <p class="numberCardProduct">
                        {{ totalCementStorage }}
                      </p>
                      <p class="font-weight-medium mt-3 ml-2 text--secondary">
                        tonna
                      </p>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="12" md="4" lg="3" sm="6">
              <v-card max-width="300" outlined style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12);
border-radius: 12px; border: none">
                <v-list-item>
                  <v-list-item-content>
                    <div class="d-flex mb-4">
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="12" fill="#F2F5FA"/>
                        <path d="M11.1699 15.44L19.9999 20.55L28.7699 15.47" stroke="#181A1D" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M20 29.61V20.54" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path
                          d="M17.9299 10.48L12.5899 13.44C11.3799 14.11 10.3899 15.79 10.3899 17.17V22.82C10.3899 24.2 11.3799 25.88 12.5899 26.55L17.9299 29.52C19.0699 30.15 20.9399 30.15 22.0799 29.52L27.4199 26.55C28.6299 25.88 29.6199 24.2 29.6199 22.82V17.17C29.6199 15.79 28.6299 14.11 27.4199 13.44L22.0799 10.47C20.9299 9.84001 19.0699 9.84001 17.9299 10.48Z"
                          stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>

                      <p class="font-weight-medium textCardProduct mt-4 ml-4 text--secondary">
                        MAVJUD QOP
                      </p>
                    </div>

                    <v-row>
                      <div class="d-flex ml-4">
                        <p class="numberCardProduct">
                          {{ totalSimpleTinStorage }}
                        </p>
                        <p class="font-weight-medium mt-3 ml-2 text--secondary">
                          oddiy
                        </p>
                      </div>

                      <div class="d-flex ml-4">
                        <p class="numberCardProduct">
                          {{ totalConvertTinStorage }}
                        </p>
                        <p class="font-weight-medium mt-3 ml-2 text--secondary">
                          konvert
                        </p>
                      </div>
                    </v-row>


                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="12" md="4" lg="3" sm="6">
              <v-card max-width="300" outlined style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12);
border-radius: 12px; border: none">
                <v-list-item>
                  <v-list-item-content>
                    <div class="d-flex mb-4 ">
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="12" fill="#F2F5FA"/>
                        <path
                          d="M17.5 21.75C17.5 22.72 18.25 23.5 19.17 23.5H21.05C21.85 23.5 22.5 22.82 22.5 21.97C22.5 21.06 22.1 20.73 21.51 20.52L18.5 19.47C17.91 19.26 17.51 18.94 17.51 18.02C17.51 17.18 18.16 16.49 18.96 16.49H20.84C21.76 16.49 22.51 17.27 22.51 18.24"
                          stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M20 15.5V24.5" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M30 20C30 25.52 25.52 30 20 30C14.48 30 10 25.52 10 20C10 14.48 14.48 10 20 10"
                              stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M30 14V10H26" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M25 15L30 10" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                      </svg>

                      <p class="font-weight-medium textCardProduct mt-4 ml-4 text--secondary">
                        QARZDORLIK
                      </p>
                    </div>
                    <div class="d-flex ml-2">
                      <p class="numberCardProduct">
                        {{ formatPrice(totalSaleDebt) }}
                      </p>
                      <p class="font-weight-medium mt-3 ml-2 text--secondary">
                        uzs
                      </p>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="12" md="4" lg="3" sm="6">
              <v-card max-width="300" outlined style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12);
border-radius: 12px; border: none">
                <v-list-item>
                  <v-list-item-content>
                    <div class="d-flex mb-4 ">
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="12" fill="#F2F5FA"/>
                        <path
                          d="M17.5 21.75C17.5 22.72 18.25 23.5 19.17 23.5H21.05C21.85 23.5 22.5 22.82 22.5 21.97C22.5 21.06 22.1 20.73 21.51 20.52L18.5 19.47C17.91 19.26 17.51 18.94 17.51 18.02C17.51 17.18 18.16 16.49 18.96 16.49H20.84C21.76 16.49 22.51 17.27 22.51 18.24"
                          stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M20 15.5V24.5" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M30 20C30 25.52 25.52 30 20 30C14.48 30 10 25.52 10 20C10 14.48 14.48 10 20 10"
                              stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M25 11V15H29" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M30 10L25 15" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                      </svg>

                      <p class="font-weight-medium textCardProduct mt-4 ml-4 text--secondary">
                        QARZLARIM
                      </p>
                    </div>
                    <div class="d-flex ml-2">
                      <p class="numberCardProduct">
                        {{ formatPrice(totalDebt) }}
                      </p>
                      <p class="font-weight-medium mt-3 ml-2 text--secondary">
                        uzs
                      </p>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </div>

    <div class="content__home">
      <v-row class="d-flex">
        <v-col cols="12">
          <chart></chart>
        </v-col>
      </v-row>
    </div>

    <div class="content__home ml-5">
      <div class="d-flex align-center justify-space-between w-100 py-2">
        <div><h1 class="ml-5">Kirim chiqimlar</h1></div>
        <div class="d-flex align-center  flex-wrap">
          <span class="mr-5">Tartib:</span>
          <div class="d-flex mr-5">
            <v-select :items="items" dense @change="changeConditionFilter" label="Saralash" hide-details style="border-radius: 14px; width: 150px;" 
            outlined class="mr-2"></v-select>
            <router-link to="enter">
              <img style="height: 40px;" src="../assets/Go.svg" alt="">
            </router-link>
          </div>
        </div>
      </div>

      <v-list class="rounded-lg bgNone" flat>

        <v-list-item-group
          v-if="!(Object.keys(incomesStorage).length === 0) || !(Object.keys(salesStorage).length === 0)"
          active-class="activeList">
          <v-row style="height: 300px; overflow-y: scroll;">

            <v-list-item v-for="(item, i) in incomesStorage" :key="item.tin_id" class=""
                          :class="[item.bg ? 'listActiveLi' : '']" style="height: 60px;">

              <v-col v-if="item.seller" style="width: 220px">
                <div class="d-flex">
                  <v-list-item-icon
                    :class="{'circleAvatar':i % 2 === 1,'circleAvatar1':i % 2 === 0,'circleAvatar2':i % 3 === 1,}">
                    <span> {{ item.seller.name[0] }}</span>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span>{{ item.seller.name.substring(15, -1) }}</span>
                  </v-list-item-title>
                </div>
              </v-col>

              <v-col cols="2">
                <v-list-item-content v-if="item.product_id==1">
                  <v-list-item-title>Sement</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-if="item.product_id==2">
                  <v-list-item-title>Qop: konvert</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-if="item.product_id==3">
                  <v-list-item-title>Qop: oddiy</v-list-item-title>
                </v-list-item-content>
              </v-col>

              <v-col cols="2">
                <v-list-item-content v-if="item.product_id==1">
                  <v-list-item-title>{{ item.amount }} t</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-if="item.product_id==2">
                  <v-list-item-title>{{ item.quantity }} dona</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-if="item.product_id==3">
                  <v-list-item-title>{{ item.quantity }} dona</v-list-item-title>
                </v-list-item-content>
              </v-col>

              <v-col v-if="item.seller">
                <v-list-item-icon>
                  <img src="../assets/enter.svg" alt="">
                </v-list-item-icon>
              </v-col>
              <v-col style="width: 100px">
                <v-list-item-content>
                  <v-list-item-title
                    class="text--secondary"><span
                    class="font-weight-medium text--primary">{{ formatPrice(item.price) }} </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col style="width: 100px">
                <v-list-item-content>
                  <v-list-item-title class="text--secondary">
                    {{ item.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>
            </v-list-item>

            <v-list-item v-for="(item, i) in salesStorage" :key="i" class=""
                          :class="[item.bg ? 'listActiveLi' : '']" style="height: 60px;">

              <v-col v-if="item.customer" style="width: 220px">
                <div class="d-flex">
                  <v-list-item-icon
                    :class="{'circleAvatar':i % 2 === 1,'circleAvatar1':i % 2 === 0,'circleAvatar2':i % 3 === 1,}">
                    <span> {{ item.customer.name[0] }}</span>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span>{{ item.customer.name.substring(15, -1) }} </span>
                  </v-list-item-title>
                </div>
              </v-col>

              <v-col cols="2">
                <v-list-item-content v-if="item.product_id==1">
                  <v-list-item-title>Sement</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-if="item.product_id==2">
                  <v-list-item-title>Qop: konvert</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-if="item.product_id==3">
                  <v-list-item-title>Qop: oddiy</v-list-item-title>
                </v-list-item-content>
              </v-col>

              <v-col style="width: 50px">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">
                    {{ item.amount }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>

              <v-col cols="2">
                <v-list-item-content v-if="item.product_id==1">
                  <v-list-item-title>{{ item.amount }} t</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-if="item.product_id==2">
                  <v-list-item-title>{{ item.quantity }} dona</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-if="item.product_id==3">
                  <v-list-item-title>{{ item.quantity }} dona</v-list-item-title>
                </v-list-item-content>
              </v-col>

              <v-col>
                <v-list-item-icon>
                  <img src="../assets/chiqim.svg" alt="">
                </v-list-item-icon>
              </v-col>

              <v-col style="width: 100px">
                <v-list-item-content>
                  <v-list-item-title
                    class="text--secondary"><span
                    class="font-weight-medium text--primary">{{ formatPrice(item.price) }} </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>

              <v-col style="width: 100px">
                <v-list-item-content>
                  <v-list-item-title class="text--secondary">
                    {{ item.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>

            </v-list-item>

          </v-row>
        </v-list-item-group>
        <Nodata v-else></Nodata>
      </v-list>

    </div>
  </div>
</template>

<script>
import chart from '../components/Chart'
// import Loader from '../components/loader/ProgressCircular'
import Nodata from '../components/base/NoDate'

export default {
  components: {
    chart, Nodata
  },
  data() {
    return {
      loadingContent: false,
      totalCementStorage: '',
      totalSimpleTinStorage: '',
      totalConvertTinStorage: '',
      debtorAnalitycs: {},
      validate: false,
      dialog: false,
      changeCondition: '',
      totalArray: [],
      items: [
        {text: 'Barchasi', icon: 'mdi-account', bg: false, value: 'all'},
        {text: 'Kirim', icon: 'mdi-clock', bg: true, value: 'income'},
        {text: 'Chiqim', icon: 'mdi-account', bg: false, value: 'sale'},

      ],
      salesStorage: [],
      incomesStorage: [],
      date: [],
      default: true,
      totalDebt: [],
      totalSaleDebt: [],
    }
  },

  mounted() {
    this.getIncomes();
    this.getSales();

    let cvartal = localStorage.getItem("cvartal");

    this.$axios.get("/get-analytic-dashboard?cvartal=" + cvartal).then(response => {
      this.totalCementStorage = response.data[0] ?? 0;
      this.totalSimpleTinStorage = response.data[1] ?? 0;
      this.totalConvertTinStorage = response.data[2] ?? 0;
    });

    this.$axios.get("/get-total-income-debt?cvartal=" + cvartal).then(response => {
      this.totalDebt = response.data.totalDebt;
    });

    this.$axios.get("/get-total-sale-debt?cvartal=" + cvartal).then(response => {
      this.totalSaleDebt = response.data.totalDebt;
    });

    this.loadingContent = true;
    this.$axios.get("/sales?cvartal=" + cvartal).then(response => {
      this.debtorAnalitycs = response.data;
      this.loadingContent = false;
    });


    this.$store.dispatch('loadSales')
    this.$store.dispatch('loadIncomes')
  },

  watch: {
    onDate() {
      this.getIncomes();
      this.getSales();
      // this.test();
    }
  },

  methods: {

    changeConditionFilter(item) {
      if (item == "income") {
        this.getIncomes();
        this.salesStorage = []
      } else if (item == "sale") {
        this.getSales();
        this.incomesStorage = []
      } else {
        this.getIncomes();
        this.getSales();
      }

    },

    getIncomes() {
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.date.length) {
        dateUrl = '?date=' + this.date;
      } else {
        dateUrl = '';
      }
      this.$axios.get('/income-storage?cvartal=' + cvartal + dateUrl).then(response => {
        this.incomesStorage = response.data;
      }).catch(error => {
        console.log(error)
      });
    },

    getSales() {
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.date.length) {
        dateUrl = '?date=' + this.date;
      } else {
        dateUrl = '';
      }
      this.$axios.get('/sale-storage?cvartal=' + cvartal + dateUrl).then(response => {
        this.salesStorage = response.data;
      }).catch(error => {
        console.log(error)
      });
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getSumOfDebtor(arr) {
      let sum = 0;
      arr.forEach((el) => {
        sum += el.payment
      })
      return sum
    }
  },

  computed: {
    sales() {
      return this.$store.getters.sales
    },

    'onDate'() {
      return this.date;
    },

    incomes() {
      return this.$store.getters.incomes
    },
    totalArr() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.totalArray.push(...this.incomes, ...this.sales)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.totalArray = [...this.totalArray].sort(() => 0.5 - Math.random());
    },

    drawerNav: {
      get: function() {
          return this.$store.state.common.drawerNav
      },
      set: function(newValue) {
          return this.$store.state.common.drawerNav = newValue
      }
    },

  }
}
</script>

<style>

</style>
