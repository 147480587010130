<template>
  <div>
      <v-navigation-drawer app width="100" class="bgNone">
        <div class="text-center  logoDiv">
          <div>
            <navigation></navigation>
          </div>
        </div>
      </v-navigation-drawer>

      <v-app-bar app elevation="0" height="100" class="" color="white">
          <div class="pa-5 d-flex justify-space-between align-center flex-wrap w-100">


            <div class="d-flex align-center">
              <v-app-bar-nav-icon @click="drawerNav = !drawerNav"
                class="hidden-md-and-up"></v-app-bar-nav-icon>

              <img class="ma-2" src="@/assets/navabarIcons/debts.svg" alt="">

              <div class="d-flex align-start flex-column justify-center ml-2">
                <h2>Moliya</h2>
                <span>Kirim-chiqimlar</span>
              </div>
            </div>

            <v-spacer></v-spacer>
            <div class="d-flex">

              <v-text-field prepend-inner-icon="mdi-magnify" outlined
                dense class="mt-1" v-model="search" hide-details
                label="Qidiruv..."></v-text-field>
            </div>
          </div>
      </v-app-bar>

      <div class="content__main">
        <div>
          <v-tabs>
            <v-tab @click="tabDate = 'daily'">Kunlik</v-tab>
            <v-tab @click="tabDate = 'weekly'">Haftalik</v-tab>
            <v-tab @click="tabDate = 'monthly'">Oylik</v-tab>
            <v-tab @click="tabDate = 'yearly'">{{ financeChangeText }}</v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <!-- <v-col cols="12">
            <v-divider style="margin-top: -40px"></v-divider>
          </v-col> -->
          <br>
          <v-row>
            <v-col cols="12" v-show="isSeller">
              <v-row class="mx-auto align-center">

                <v-col cols="12" md="4" lg="3" sm="6">
                  <v-card max-width="350" outlined style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12);
border-radius: 12px;border: none">
                    <v-list-item>
                      <v-list-item-content>
                        <div class="d-flex mb-4">
                          <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" rx="12" fill="#F2F5FA"/>
                            <path
                              d="M17.5 21.75C17.5 22.72 18.25 23.5 19.17 23.5H21.05C21.85 23.5 22.5 22.82 22.5 21.97C22.5 21.06 22.1 20.73 21.51 20.52L18.5 19.47C17.91 19.26 17.51 18.94 17.51 18.02C17.51 17.18 18.16 16.49 18.96 16.49H20.84C21.76 16.49 22.51 17.27 22.51 18.24"
                              stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20 15.5V24.5" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M30 20C30 25.52 25.52 30 20 30C14.48 30 10 25.52 10 20C10 14.48 14.48 10 20 10"
                                  stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M25 11V15H29" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M30 10L25 15" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                          </svg>

                          <p class="font-weight-medium textCardProduct mt-4 ml-2 text--secondary">
                            KIRIM
                          </p>
                        </div>
                        <div class="d-flex ml-2">
                          <p class="numberCardProduct">
                            {{ formatPrice(totalProfit) }}
                          </p>
                          <p class="font-weight-medium mt-3 ml-2 text--secondary">
                            uzs
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4" lg="3" sm="6">
                  <v-card
                    max-width="350"
                    outlined
                    style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12);
border-radius: 12px;border: none"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <div class="d-flex mb-4">
                          <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" rx="12" fill="#F2F5FA"/>
                            <path
                              d="M17.5 21.75C17.5 22.72 18.25 23.5 19.17 23.5H21.05C21.85 23.5 22.5 22.82 22.5 21.97C22.5 21.06 22.1 20.73 21.51 20.52L18.5 19.47C17.91 19.26 17.51 18.94 17.51 18.02C17.51 17.18 18.16 16.49 18.96 16.49H20.84C21.76 16.49 22.51 17.27 22.51 18.24"
                              stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20 15.5V24.5" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M30 20C30 25.52 25.52 30 20 30C14.48 30 10 25.52 10 20C10 14.48 14.48 10 20 10"
                                  stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M30 14V10H26" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M25 15L30 10" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                          </svg>

                          <p class="font-weight-medium textCardProduct mt-4 ml-2 text--secondary">
                            CHIQIM
                          </p>
                        </div>
                        <div class="d-flex ml-2">
                          <p class="numberCardProduct">
                            {{ formatPrice(totalExpense) }}
                          </p>
                          <p class="font-weight-medium mt-3 ml-2 text--secondary">
                            uzs
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4" lg="3" sm="6">
                  <v-card
                    max-width="350"
                    outlined
                    style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12);
border-radius: 12px;border: none"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <div class="d-flex mb-4">
                          <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" rx="12" fill="#F2F5FA"/>
                            <path
                              d="M16.6719 22.3298C16.6719 23.6198 17.6619 24.6598 18.8919 24.6598H21.4019C22.4719 24.6598 23.3419 23.7498 23.3419 22.6298C23.3419 21.4098 22.8119 20.9798 22.0219 20.6998L17.9919 19.2998C17.2019 19.0198 16.6719 18.5898 16.6719 17.3698C16.6719 16.2498 17.5419 15.3398 18.6119 15.3398H21.1219C22.3519 15.3398 23.3419 16.3798 23.3419 17.6698"
                              stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20 14V26" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path
                              d="M20 30C25.5228 30 30 25.5228 30 20C30 14.4772 25.5228 10 20 10C14.4772 10 10 14.4772 10 20C10 25.5228 14.4772 30 20 30Z"
                              stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          <p class="font-weight-medium textCardProduct mt-4 ml-2 text--secondary">
                            SOF FOYDA
                          </p>
                        </div>
                        <div class="d-flex ml-2">
                          <p class="numberCardProduct">
                            {{ formatPrice(totalNetProfit) }}
                          </p>
                          <p class="font-weight-medium mt-3 ml-2 text--secondary">
                            uzs
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-row class="mx-auto align-center">

                <v-col cols="12" md="4" lg="3" sm="6">
                  <v-card
                    max-width="350"
                    outlined
                    style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12);
border-radius: 12px;border: none"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <div class="d-flex mb-4">
                          <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" rx="12" fill="#F2F5FA"/>
                            <path
                              d="M17.5 21.75C17.5 22.72 18.25 23.5 19.17 23.5H21.05C21.85 23.5 22.5 22.82 22.5 21.97C22.5 21.06 22.1 20.73 21.51 20.52L18.5 19.47C17.91 19.26 17.51 18.94 17.51 18.02C17.51 17.18 18.16 16.49 18.96 16.49H20.84C21.76 16.49 22.51 17.27 22.51 18.24"
                              stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20 15.5V24.5" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M30 20C30 25.52 25.52 30 20 30C14.48 30 10 25.52 10 20C10 14.48 14.48 10 20 10"
                                  stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M30 14V10H26" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M25 15L30 10" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                          </svg>

                          <p class="font-weight-medium textCardProduct mt-4 ml-2 text--secondary">
                            Qarzlarim
                          </p>
                        </div>
                        <div class="d-flex ml-2">
                          <p class="numberCardProduct">
                            {{ formatPrice(totalIncomeDebt) }}
                          </p>
                          <p class="font-weight-medium mt-3 ml-2 text--secondary">
                            uzs
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4" lg="3" sm="6">
                  <v-card
                    max-width="350"
                    outlined
                    style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12); border-radius: 12px;border: none"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <div class="d-flex mb-4">
                          <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" rx="12" fill="#F2F5FA"/>
                            <path
                              d="M16.6719 22.3298C16.6719 23.6198 17.6619 24.6598 18.8919 24.6598H21.4019C22.4719 24.6598 23.3419 23.7498 23.3419 22.6298C23.3419 21.4098 22.8119 20.9798 22.0219 20.6998L17.9919 19.2998C17.2019 19.0198 16.6719 18.5898 16.6719 17.3698C16.6719 16.2498 17.5419 15.3398 18.6119 15.3398H21.1219C22.3519 15.3398 23.3419 16.3798 23.3419 17.6698"
                              stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20 14V26" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path
                              d="M20 30C25.5228 30 30 25.5228 30 20C30 14.4772 25.5228 10 20 10C14.4772 10 10 14.4772 10 20C10 25.5228 14.4772 30 20 30Z"
                              stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          <p class="font-weight-medium textCardProduct mt-4 ml-2 text--secondary">
                            Qarzdorlar
                          </p>
                        </div>
                        <div class="d-flex ml-2">
                          <p class="numberCardProduct">
                            {{ formatPrice(totalSaleDebt) }}
                          </p>
                          <p class="font-weight-medium mt-3 ml-2 text--secondary">
                            uzs
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4" lg="3" sm="6">

                  <v-card
                    max-width="350"
                    outlined
                    style="box-shadow: 0px 0px 24px rgba(145, 163, 184, 0.12);
border-radius: 12px;border: none"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <div class="d-flex mb-4">
                          <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" rx="12" fill="#F2F5FA"/>
                            <path
                              d="M17.5 21.75C17.5 22.72 18.25 23.5 19.17 23.5H21.05C21.85 23.5 22.5 22.82 22.5 21.97C22.5 21.06 22.1 20.73 21.51 20.52L18.5 19.47C17.91 19.26 17.51 18.94 17.51 18.02C17.51 17.18 18.16 16.49 18.96 16.49H20.84C21.76 16.49 22.51 17.27 22.51 18.24"
                              stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20 15.5V24.5" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M30 20C30 25.52 25.52 30 20 30C14.48 30 10 25.52 10 20C10 14.48 14.48 10 20 10"
                                  stroke="#181A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M30 14V10H26" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M25 15L30 10" stroke="#181A1D" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                          </svg>

                          <p class="font-weight-medium textCardProduct mt-4 ml-2 text--secondary">
                            UMUMIY RASXOD
                          </p>
                        </div>
                        <div class="d-flex ml-2">
                          <p class="numberCardProduct">
                            {{ formatPrice(totalExpenseSum) }}
                          </p>
                          <p class="font-weight-medium mt-3 ml-2 text--secondary">
                            uzs
                          </p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>

              </v-row>
            </v-col>
          </v-row>
        </div>

        <div>
          <v-row>
            <v-col cols="12">
              <div class="justify-space-between d-flex" style="align-items: center;height: 0;margin-top: 3rem; ">
                <div>
                  <v-select :items="items" item-value="type" dense v-model="filterList" label="Saralash"
                            style="border-radius: 14px;width: 150px" outlined class="ml-3"></v-select>
                </div>
                <div class="d-flex">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Sanani tanlang..."
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                      :min="getMinDate"
                      :max="getMaxDate"
                      range
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="date = []"
                      >
                        O'chirish
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <div class="d-flex flex-column px-2">
            <v-data-table
              :items="incomesList"
              hide-default-header
              hide-default-footer
              v-if="filterList === 'income'"
            >
              <template v-slot:header>
                <v-list-item class="d-flex justify-space-between w-100">
                    <v-list-item-title class="text--secondary">Summa</v-list-item-title>
                    <v-list-item-title class="text--secondary">Turi</v-list-item-title>
                    <v-list-item-title class="text--secondary">Manba</v-list-item-title>
                    <v-list-item-title class="text--secondary">Sana</v-list-item-title>
                </v-list-item>
              </template>

              <template v-slot:item="{ item }">
                <v-list-item-group>

                    <v-list-item style="height: 60px">

                      <v-list-item-title>
                        <b>{{ formatPrice(item.payment) }}</b> so'm
                      </v-list-item-title>


                      <v-list-item-title>
                        <img height="30" width="auto" src="@/assets/enter.svg" alt="">
                      </v-list-item-title>


                      <v-list-item-title>
                        <b>Sement sotildi</b>
                      </v-list-item-title>


                      <v-list-item-title class="text--secondary"
                      >{{ item.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                      </v-list-item-title>

                    </v-list-item>


                </v-list-item-group>
              </template>
            </v-data-table>
            <v-pagination
              v-model="incomesListPagination.current"
              :length="incomesListPagination.total"
              :total-visible="7"
              @input="onIncomesListPageChange"
              color="#507BE8"
              class="mt-2 mb-4"
              v-if="filterList === 'income'"
            ></v-pagination>


            <v-data-table
              :items="expendituresList"
              hide-default-header
              hide-default-footer
              v-if="filterList === 'expenditure'"
            >
              <template v-slot:header>
                <v-list-item class="d-flex justify-space-between w-100">
                    <v-list-item-title class="text--secondary">Summa</v-list-item-title>
                    <v-list-item-title class="text--secondary">Turi</v-list-item-title>
                    <v-list-item-title class="text--secondary">Manba</v-list-item-title>
                    <v-list-item-title class="text--secondary">Sana</v-list-item-title>
                </v-list-item>
              </template>

              <template v-slot:item="{ item }">
                <v-list-item-group>

                  <v-list-item style="height: 60px">

                    <v-list-item-title>
                      <b> {{ formatPrice(item.payment) }} </b> so'm
                    </v-list-item-title>


                    <v-list-item-title>
                      <img height="30" src="@/assets/chiqim.svg" alt="">
                    </v-list-item-title>


                    <v-list-item-title>
                      <b v-if="item.income.product_type === 'cement'"> Sement sotib olindi </b>
                      <b v-if="item.income.product_type === 'tin'"> Qop sotib olindi </b>
                    </v-list-item-title>

                    <v-list-item-title class="text--secondary"
                    >{{ item.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                    </v-list-item-title>

                  </v-list-item>

                </v-list-item-group>
              </template>
            </v-data-table>
            <v-pagination
              v-model="expendituresListPagination.current"
              :length="expendituresListPagination.total"
              :total-visible="7"
              @input="onExpenditureListPageChange"
              color="#507BE8"
              class="mt-2 mb-4"
              v-if="filterList === 'expenditure'"
            ></v-pagination>




            <v-data-table
              :items="expenseList"
              hide-default-header
              hide-default-footer
              v-if="filterList === 'expense'"
            >
              <template v-slot:header>
                <v-list-item class="d-flex justify-space-between w-100">
                    <v-list-item-title class="text--secondary">Summa</v-list-item-title>
                    <v-list-item-title class="text--secondary">Turi</v-list-item-title>
                    <v-list-item-title class="text--secondary">Manba</v-list-item-title>
                    <v-list-item-title class="text--secondary">Sana</v-list-item-title>
                </v-list-item>
              </template>

              <template v-slot:item="{ item }">
                <v-list-item-group>

                  <v-list-item style="height: 60px" @click="openUpdateExpenseModal(item.id)">
                    <v-list-item-title>
                      <b>{{ formatPrice(item.sum) }}</b> so'm
                    </v-list-item-title>

                    <v-list-item-title>
                      <img height="30" src="@/assets/chiqim.svg" alt="">
                    </v-list-item-title>


                    <v-list-item-title>
                      <b>{{ item.category.name }}</b>
                    </v-list-item-title>

                    <v-list-item-title class="text--secondary">
                      {{ item.created_at | moment("timezone", "Asia/Tashkent", "DD MMMM YYYY") }}
                    </v-list-item-title>

                  </v-list-item>

                </v-list-item-group>
              </template>
            </v-data-table>
            <v-pagination
              v-model="expenseListPagination.current"
              :length="expenseListPagination.total"
              :total-visible="7"
              @input="onExpenseListPageChange()"
              color="#507BE8"
              class="mt-2 mb-4"
              v-if="filterList === 'expense'"
            ></v-pagination>
          </div>


        </div>

        <v-dialog
          v-model="addExpensesModal"
          style="position: absolute;margin-bottom: 250px;"
          max-width="380"
        >
          <template v-slot:activator="{ on, attrs }">
            <img style="position: fixed; right: 2%; bottom: 6%; width: 50px; z-index: 999;" v-bind="attrs" v-on="on" src="@/assets/add-square.svg" alt="">
          </template>
          <v-card class="pa-5" elevation="0">
            <div class="d-flex">
              <div><p class="mt-3 ml-3 font-weight-medium">Formani to’ldiring</p></div>
              <v-spacer></v-spacer>
              <v-btn elevation="0" icon @click="addExpensesModal=false"><img
                src="@/assets/xExit.svg" alt="">
              </v-btn>
            </div>


            <v-form ref="form" lazy-validation>

              <p class="font-weight-medium">Sanani tanlang</p>
              <v-menu
                ref="calendarClose"
                v-model="calendarClose"
                :close-on-content-click="false"
                :return-value.sync="expensesFields.created_at"
                transition="scale-transition"
                offset-y
                min-width="auto"
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="expensesFields.created_at"
                    prepend-icon="mdi-calendar"
                    outlined
                    :rules="[  v => !!v ||' Sana majburiy']"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="expensesFields.created_at" :min="getMinDate" :max="getMaxDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="calendarClose = false">
                    Cancel
                  </v-btn>
                  <v-btn text
                          color="primary"
                          @click="$refs.calendarClose.save(expensesFields.created_at)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <p class="font-weight-medium">Summa</p>
              <v-text-field v-model="expensesFields.sum" class="mt-2" style="border-radius: 18px;"
                            :rules="[  v => !!v || 'Summa majburiy']"
                            outlined>
              </v-text-field>


              <p class="font-weight-medium"> Harajat tarifi </p>
              <v-textarea v-model="expensesFields.description" class="mt-2" style="border-radius: 18px;"
                          :rules="[  v => !!v ||' Harajat majburiy']"
                          outlined placeholder="">
              </v-textarea>


              <div class="d-flex justify-space-around align-center w-100">
                <v-btn
                  color="white"
                  large
                  elevation="0"
                  style="color: #F54E4E; border-radius: 14px;width: 150px; height: 50px"
                  @click="addExpensesModal=false"
                >
                  Bekor qilish
                </v-btn>
                <v-btn
                  v-show="!expenseId"
                  color="primary"
                  class="mr-4 "
                  large
                  elevation="0"
                  style="background: #507BE8;border-radius: 14px;width: 150px; height: 50px"
                  @click="createExpense"
                  :disabled="!disableExpensesButton"
                >
                  Xarajat qo'shish
                </v-btn>

                <v-btn
                  v-show="expenseId"
                  color="primary"
                  large
                  elevation="0"
                  style="background: #507BE8;border-radius: 14px;width: 150px; height: 50px"
                  @click="updateExpense"
                  :disabled="!disableExpensesButton"
                >
                  Yangilash
                </v-btn>

              </div>

            </v-form>

          </v-card>
        </v-dialog>


        <!--  Confirm delete modal  -->
        <template>
          <v-row justify="center">
            <v-dialog
              v-model="confirmDelete"
              persistent
              max-width="290"
            >
              <v-card>
                <v-card-title class="text-h5">
                  Xarajatni o'chirish?
                </v-card-title>
                <v-card-text>Quyidagi xarajatni o'chirgandan so'ng uni qayta tiklay olmaysiz</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="confirmDelete = false"
                  >
                    Yoq
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="deleteExpense()"
                  >
                    HA
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
      </div>

  </div>
</template>

<script>
import navigation from '../../components/NavigationDrawerSeller'

export default {
  data() {
    return {
      isSeller:false,
      totalProfit: '',
      totalExpense: '',
      totalNetProfit: '',
      netPercentage: '',
      dateOfAnalitics: [],
      date: [],
      menu: '',
      valid: false,
      searchQuery: '',
      incomesList: [],
      incomesListPagination: {
        current: 1,
        total: 0
      },
      expendituresList: [],
      expendituresListPagination: {
        current: 1,
        total: 0
      },
      expenseList: [],
      expenseListPagination: {
        current: 1,
        total: 0
      },
      filterList: 'income',
      dialog: '',
      modalArray: '',
      dateToLog: [],
      tab: '',
      items: [
        {text: 'Kirim', type: 'income', icon: 'mdi-clock', bg: true},
        {text: 'Chiqim', type: 'expenditure', icon: 'mdi-account', bg: false},
        {text: 'Xarajat', type: 'expense', icon: 'mdi-account', bg: false},
      ],
      //modal
      search: '',
      selection: [],
      productId: '',
      few: '',
      addExpensesModal: false,
      calendarClose: false,
      expenseId: '',
      editExpense: false,
      expensesFields: {
        id: '',
        category_id: 1,
        sum: '',
        description: '',
        created_at: ''
      },
      confirmDelete: false,
      categories: [],
      tabDate: 'daily',
      totalIncomeDebt: '',
      totalSaleDebt: '',
      totalExpenseSum: ''
    }
  },

  components: {
    navigation
  },

  methods: {
   async calculateFinanceDatas() {
      let cvartal = localStorage.getItem("cvartal");

     await this.$axios.get("/get-total-profits-by-date?date=" + this.tabDate + '&cvartal=' + cvartal).then(response => {
        this.totalProfit = response.data.profits;
      }).then(() => {
        this.$axios.get("/get-total-expenses-by-date?date=" + this.tabDate + '&cvartal=' + cvartal).then(response => {
          this.totalExpense = response.data.expenses;
        }).then(() => {
          this.totalNetProfit = this.totalProfit - this.totalExpense;
        }).then(() => {
          this.netPercentage = (this.totalNetProfit / this.totalProfit) * 100
        });
      });
    },

    // getExpenseCategories() {
    //   this.$axios.get("/categories").then(response => {
    //     this.categories = response.data
    //   });
    // },

    getIncomesList() {
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.date.length) {
        dateUrl = '&date=' + this.date;
      } else {
        dateUrl = '';
      }
      // console.log('i-d' + dateUrl);
      this.$axios.get("/get-payments?page=" + this.incomesListPagination.current + '&cvartal=' + cvartal + dateUrl).then(response => {
        // console.log(response);
        this.incomesList = response.data.data;
        this.incomesListPagination.current = response.data.current_page;
        this.incomesListPagination.total = response.data.last_page;
      });
    },

    onIncomesListPageChange() {
      this.getIncomesList();
    },

    getExpenditureList() {
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.date.length) {
        dateUrl = '&date=' + this.date;
      } else {
        dateUrl = '';
      }
      this.$axios.get("/expenses?page=" + this.expenseListPagination.current + '&cvartal=' + cvartal + dateUrl).then(response => {
        this.expenseList = response.data.data;
        this.expenseListPagination.current = response.data.current_page;
        this.expenseListPagination.total = response.data.last_page;
      });
    },

    onExpenditureListPageChange() {
      this.getExpenditureList();
    },

    getExpenseList() {
      let dateUrl;
      let cvartal = localStorage.getItem("cvartal");

      if (this.date.length) {
        dateUrl = '&date=' + this.date;
      } else {
        dateUrl = '';
      }
      this.$axios.get("/expenses?page=" + this.expenseListPagination.current + '&cvartal=' + cvartal + dateUrl).then(response => {
        this.expenseList = response.data.data;
        this.expenseListPagination.current = response.data.current_page;
        this.expenseListPagination.total = response.data.last_page;
      });
    },

    onExpenseListPageChange() {
      this.getExpenditureList();
    },

    createExpense() {
      this.expenseId = '';
      this.expensesFields.category_id = 1;
      this.$axios.post("/expenses", this.expensesFields)
        .then(response => {
          // console.log('create-', response.data);
          //Update table
          this.getExpenseList();
          //Close modal
          this.addExpensesModal = false;
          //Success notification message
          this.$toast.success(response.data.message);
          this.expensesFields = {};
        })
    },

    openUpdateExpenseModal(id) {
      this.expenseId = id;
      this.addExpensesModal = true;
      this.editExpense = true;
      if (this.expenseId) {
        this.$axios.get("/expenses/" + this.expenseId).then(response => {
          this.expensesFields = response.data;
        });
      }
    },

    updateExpense() {
      this.$axios
        .put("/expenses/" + this.expenseId, this.expensesFields)
        .then(response => {
          this.getExpenseList();
          this.addExpensesModal = false;
          //Success notification message
          this.$toast.success(response.data.message);
          this.expensesFields = {};
          this.expenseId = ''
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            //Error notification message
            this.$toast.error("Iltimos, qayta urinib ko'ring!");
          }
        });
    },

    deleteExpense() {
      this.$axios.delete("/expenses/" + this.expenseId)
        .then(response => {
          this.getExpenseList();
          this.addExpensesModal = false;
          this.confirmDelete = false;
          this.$toast.success(response.data.message);
          this.expensesFields = {};
          this.expenseId = ''
        }).catch(error => {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
          //Error notification message
          this.$toast.error("Iltimos, qayta urinib ko'ring!");
        }
      });

    },

    getTotalIncomeDebt() {
      let cvartal = localStorage.getItem("cvartal");
      this.$axios.get("/get-total-income-debt?cvartal=" + cvartal).then(response => {
        // console.log('debt - ', response.data)
        this.totalIncomeDebt = response.data.totalDebt;
      });
    },

    //get-total-sale-debt
    getTotalSaleDebt() {
      let cvartal = localStorage.getItem("cvartal");
      this.$axios.get("/get-total-sale-debt?cvartal=" + cvartal).then(response => {
        // console.log('debt - ', response.data)
        this.totalSaleDebt = response.data.totalDebt;
      });
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

  },

  computed: {

    financeChangeText() {
      let cvartal = localStorage.getItem("cvartal");
      if (cvartal.substring(5, 10) === "01-01" && cvartal.substring(16, 21) === "03-31") {
        return "1-kvartal"
      } else if (cvartal.substring(5, 10) === "04-01" && cvartal.substring(16, 21) === "06-30") {
        return "2-kvartal"
      } else if (cvartal.substring(5, 10) === "07-01" && cvartal.substring(16, 21) === "09-30") {
        return "3-kvartal"
      } else if (cvartal.substring(5, 10) === "10-01" && cvartal.substring(16, 21) === "12-31") {
        return "4-kvartal"
      }
      return "Yillik"
    },

    getMinDate() {
      let cvartal = localStorage.getItem("cvartal");
      return cvartal.substring(0, 10);
    },

    getMaxDate() {
      let cvartal = localStorage.getItem("cvartal");
      return cvartal.substring(11);
    },

    disableExpensesButton() {
      return this.expensesFields.description &&
        this.expensesFields.sum && this.expensesFields.created_at
    },

    'onTabDate'() {
      return this.tabDate;
    },

    'onDate'() {
      return this.date;
    },
    'onAddExpensesModal'() {
      return this.addExpensesModal;
    },

    drawerNav: {
        get: function() {
            return this.$store.state.common.drawerNav
        },
        set: function(newValue) {
            return this.$store.state.common.drawerNav = newValue
        }
    },

  },

  watch: {

    onTabDate() {
      this.calculateFinanceDatas();
    },

    onDate() {
      this.getIncomesList();
      this.getExpenditureList();
      this.getExpenseList();
    },

    onAddExpensesModal() {
      if (!this.addExpensesModal) {
        this.expensesFields = {};
        this.expenseId = '';
      }
    }

  },

  // components: {Nodata},

  mounted() {
    // this.getExpenseCategories();
    this.calculateFinanceDatas();
    this.getIncomesList();
    this.getExpenditureList();
    this.getExpenseList();
    this.getTotalIncomeDebt();
    this.getTotalSaleDebt();

    let cvartal = localStorage.getItem("cvartal");

    this.$axios.get("/get-total-expense?cvartal=" + cvartal).then(response => {
      this.totalExpenseSum = response.data;
    });
  }

}
</script>


<style scoped>

</style>
